import { Layout, Col, Row } from 'antd';
import React, { memo, useState } from 'react';
import './PersonalAccountSupplerData.scss';
import { Column } from '@ant-design/charts';
import { useEffect } from 'react';
import userApi from '../../../core/api/userApi';
import { v4 } from 'uuid';

type PropsType = {
    user_id: string
}

const PersonalAccountSupplerData: React.FC<PropsType> = (props) => {
    const [tableData, setTableData] = useState<any[]>([]);
    
    const config = {
        data: tableData,
        xField: 'month_name',
        yField: 'orders_sell_sum',
        meta: {
            month_name: { alias: 'месяц' },
            orders_sell_sum: { alias: `продано лидов на сумму` },
        },
    };

    let orders_sell_sum: number = 0;
    let orders_sell_count: number = 0;

    useEffect(() => {
        let tmp: any;
        
        const date = new Date()
        const month = Math.abs(date.getMonth()-14) <10 ? '0'+Math.abs(date.getMonth()-14) : Math.abs(date.getMonth()-14)
        const year = (date.getFullYear()-1)
        
        userApi.getSellInfo({user_id: props.user_id, from: `${month+'.'+year}` }).then((res) =>{
            if (res) {
                tmp = res;
                setTableData(tmp.result);
            }
        })
        .catch((error) => {
            console.log(error)
        })        
    }, [props.user_id])

    tableData.forEach(item => {
        orders_sell_sum += item.orders_sell_sum;
        orders_sell_count += item.orders_sell_count;
    })

    return (
        <div className='supplier-account'>
            <h3 className="supplier-account__title">
                Статистика
            </h3>
            <Layout className="supplier-account__wrap" style={{ maxHeight: '70vh', overflow: 'scroll' }}>
                <Row >
                    <Col className="supplier-account__chart" xs={24} lg={18}><Column {...config} /></Col>
                    <Col lg={4} xs={24} offset={1} className="supplier-account__statistics">
                        <h4 className="supplier-account__subtitle">
                            За этот месяц
                    </h4>
                        <p className="supplier-account__text">
                            Продано лидов
                    <span className="supplier-account__number">
                                 {orders_sell_count}
                            </span>
                        </p>
                        <p className="supplier-account__text">
                            На сумму
                    <span className="supplier-account__number">
                               {orders_sell_sum}&nbsp;&#8381;
                                
                    </span>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="supplier-account__chart-info" xs={24} >
                        <table className="supplier-account__table">
                            <tbody>
                            {
                                tableData.map((item, index) => { 
                                    return (
                                        <tr key={v4()}>
                                            <td className="supplier-account__cell">
                                                <span className="supplier-account__text_chart">Период</span>
                                                <span className="supplier-account__title_chart">{item.month_name}</span>
                                            </td>
                                            <td className="supplier-account__cell">
                                                <span className="supplier-account__text_chart">Продано&nbsp;лидов</span>
                                                <span className="supplier-account__title_chart">{item.orders_sell_count}</span>
                                            </td>
                                            <td className="supplier-account__cell">
                                                <span className="supplier-account__text_chart">На&nbsp;сумму</span>
                                                <span className="supplier-account__title_chart">{item.orders_sell_sum}&nbsp;&#8381;</span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Layout>
        </div >
    )
}

export default memo(PersonalAccountSupplerData);