import React, {memo, useState} from 'react';
import {Field, reduxForm, Form} from 'redux-form';
import {LOGIN_FORM} from '../../enum';
import './LoginForm.scss';
import Hide from '../../../core/images/Hide';
import Show from '../../../core/images/Show';
import {Link} from 'react-router-dom';
import {validators} from '../../../core/utils/validators';


const renderTextField = (field: any) => {

    return (
        <div>
            <input {...field.input} {...field} />
            {field.meta.touched && field.meta.error &&
                <span className="login-form__error">{field.meta.error}</span>}
        </div>
    )
}

const LoginForm: React.FC = (props: any) => {
    const [isPassowordShown, setIsPasswordShown] = useState<boolean>(false)
    return (
        <Form className="login-form"
            onSubmit={props.handleSubmit}
        >
            <label className="login-form__label">{LOGIN_FORM.labelUsername}
            <Field      
                className="login-form__input"      
                name="username"
                component={renderTextField}
                placeholder={LOGIN_FORM.placeholderUsername}
                validate={[validators.required]}
                type="text" />
            </label>
            
            <label className="login-form__label">{LOGIN_FORM.labelPassword}
            <Field
                className="login-form__input"
                name="password"
                component={renderTextField}
                validate={[validators.required]}
                placeholder={LOGIN_FORM.placeholderPassword}
                type={`${isPassowordShown?"text":"password"}`}  />
                <span onClick={()=>{setIsPasswordShown(!isPassowordShown)}} className="login-form__eye">{isPassowordShown?<Show/>:<Hide/>}</span>
            </label>
            <div className="login-form__wrap">
                <Link to="#" className="login-form__link">Забыли пароль?</Link>
            <button className="login-form__button"
            //  onClick={showModal} // invoke modal sms confirm 
              type="submit">{LOGIN_FORM.login}</button>
            </div>
        </Form>
    )
}

const LoginReduxForm = reduxForm({form: 'login'})(LoginForm)

export default memo(LoginReduxForm);
