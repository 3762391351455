import React, { memo } from 'react';
import { Tabs } from 'antd';
import ToolsTabsContent from '../tools-tabs-content/ToolsTabsContent';
import './ToolsTabs.scss';
import { v4 } from 'uuid';
const { TabPane } = Tabs;



const ToolsTabs: React.FC = () => {


    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="SMS" key={v4()}>
                    <ToolsTabsContent tabType={'SMS'} />
                </TabPane>
                <TabPane tab="IVR" key={v4()}>
                    <ToolsTabsContent tabType={'IVR'} />
                </TabPane>
                <TabPane tab="Viber" key={v4()}>
                    <ToolsTabsContent tabType={'Viber'} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default memo(ToolsTabs)