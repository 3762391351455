import React, { memo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Popover, Button } from 'antd';
import './Balance.scss';
import { useState } from 'react';
import { useSelector } from 'react-redux'
import { rootState } from '../../../core/types/rootState'

const Balance: React.FC = () => {
    const initialBalance = useSelector((state: rootState) => state.user.balance)
    const [balance, setBalance] = useState(initialBalance)


    const [phone, setPhone] = useState<string>("+7 095 322 22 33 22")

    return (
        <div className="balance-card">
            <p className="balance-card__text">Баланс: {balance}&nbsp;&#8381;</p>
            <Popover placement="bottomRight" title={'Чтобы пополнить баланс свяжитесь с менеджером:'} content={phone} trigger="click">
                <Button className="balance-card__button" style={{ padding:0, margin: 0, boxSizing:'border-box', textAlign: 'center', outline:'none', border: 'none'}}>
                    <PlusOutlined  style={{ fontSize: '20px', borderRadius: '5px', padding: '5px', width: '100%', color: '#ffffff', borderColor: '#444853', backgroundColor: '#444853' }} />
                </Button>
            </Popover>
        </div>
    )
}

export default memo(Balance);