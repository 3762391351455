import { http } from '../infrastructure/Https';
import tokenService from '../services/TokenService';

const ROOT_URL = '/api/v1/purchases';

class PurchasesApi {
    getPurchasesList(data: {}){
        const accessToken = tokenService.getAccessToken();
        return http.get(`${ROOT_URL}`, data, accessToken)
    }
    getInfo(id: string) {
        const accessToken = tokenService.getAccessToken();
        return http.get(`${ROOT_URL}/${id}`, null, accessToken);
    }
    editInfo(data: object) {
        const accessToken = tokenService.getAccessToken();
        return http.put(`${ROOT_URL}/meta`, null, data, accessToken);
    }    
    getSelectedLeadCSV(data: any[]) {
        const accessToken = tokenService.getAccessToken();
        return http.put(`${ROOT_URL}/csv`, null, data, accessToken);
    } 
};


const purchasesApi = new PurchasesApi();
export default purchasesApi;