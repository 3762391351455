import { UserState, UserActionTypes } from '../../types/user'


export const saveUser = (payload: UserState) => {
    return { type: UserActionTypes.SAVE_USER, payload: payload };
};



// export const fetchUsers = () => {
//     return async (dispatch: Dispatch<UserAction>) => {
//             try {
//                 dispatch({type: UserActionTypes.FETCH_USER})
//                 const response = await axios.get('https://jsonplaceholder.typicode.com/users')
//                 dispatch({type: UserActionTypes.PUT_USER_SUCCESS, payload: response.data})
//             } catch (e) {
//                 dispatch({
//                     type: UserActionTypes.PUT_USER_ERROR, 
//                     payload: 'Произошла ошибка при загрузке пользователя'
//                 })
//             }
//     }
// }
