export interface RegistrationState {
    type: string | null | undefined,

}

export enum RegistrationActionTypes {
    REGISTRATION_TYPE = 'REGISTRATION_TYPE',
}
interface SetRegistrationType {
    type: RegistrationActionTypes.REGISTRATION_TYPE;
    payload?: RegistrationState["type"];
}


export type RegistrationAction = SetRegistrationType 
