import {http} from '../infrastructure/Https'
import tokenService from '../services/TokenService'

const ROOT_URL = '/api/v1/operations'

class OperationsApi{
    makePurchase(data: object){
        const accessToken = tokenService.getAccessToken();
        return http.post(`${ROOT_URL}/purchase`, null, data, accessToken);  
    }
    topUpBalance(data: object){
        const accessToken = tokenService.getAccessToken();
        return http.post(`${ROOT_URL}/fund`, null, data, accessToken);  
    }
}

const operationsApi = new OperationsApi();
export default operationsApi