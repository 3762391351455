import {Col, Layout, Row, Typography} from 'antd'
import React, {memo, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import MainHeader from '../../app/components/header'
import NavigationMenu from '../../app/components/navigation-menu'
import ProfileDataBox from '../../app/components/profile-data-box'
import {PROFILE_BOXES} from './constans'
import userService from '../../core/services/UserService'
import {rootState} from '../../core/types/rootState'
import './Profile.scss';


const ProfilePage: React.FC = () => {
    interface User {
        id: string;
        avatar_url: string;
        name: string;
        roles: any[];
        family_name: string;
        last_name: string;
        email: string;
        phone: string;
        balance: number;
        birth_date: string;
        company_name: string;
        user_manager_email: string;
        user_manager_phone: string;
    }
    const initialUser: User = {
        id: '',
        avatar_url: '',
        name: "",
        roles: [''],
        family_name: "",
        last_name: "",
        email: "",
        phone: "",
        balance: 0,
        birth_date: "",
        company_name: "",
        user_manager_email: "",
        user_manager_phone: "",
    }

    const id = useSelector((state: rootState) => state.user.id);
    const [user, setUser] = useState<User>(initialUser)

    useEffect(() => {
        let tmp: any; 
        userService.getUserInfo(id)
            .then((res) => {
                tmp = res;
                setUser(tmp)
            })
            .catch((err) => console.log(err));
    }, [id])

    return (
        <Layout className="page">
            <Row className='page__wrap' gutter={[40, 8]} >
                <Col className='page__wrap page__wrap_menu' sm={4}>
                    <NavigationMenu />
                </Col>
                <Col className='page__wrap page__wrap_table' xs={24} sm={20}>
                    <MainHeader />
                    <div className='profile'>
                        <Typography.Title className='profile__title' level={2}>Профиль пользователя</Typography.Title>
                        <Typography.Title level={4}>Личные данные</Typography.Title>
                        <Row justify='space-between' gutter={30}>
                            {user &&
                                <>
                                    <Col xs={24} md={12}>
                                        <ProfileDataBox
                                            rules={[{required: true, min: 1, max: 50}
                                                , {required: true, min: 1, max: 50}
                                                , {required: true, min: 1, max: 50},]}
                                            title={PROFILE_BOXES.name}
                                            info={[user.name || '', user.family_name || '', user.last_name || '']}
                                            type={['text']}
                                            name={['name', 'family_name', 'last_name']}
                                            label={['Имя', 'Отчество', 'Фамилия']}
                                            role={user.roles[0].role_id}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ProfileDataBox title={PROFILE_BOXES.date}
                                            rules={[{required: true}]}
                                            isDate={true}
                                            type={['date']}
                                            info={[user.birth_date || '']}
                                            name={['birth_date']}
                                            label={['Дата рождения']}
                                            role={user.roles[0].role_id}
                                        />
                                    </Col>
                                </>}

                        </Row>
                        <Typography.Title level={4}>Контактные данные</Typography.Title>
                        <Row justify='space-between' gutter={[30, 50]}>
                            {user && <>
                                <Col xs={24} md={12}>
                                    <ProfileDataBox title={PROFILE_BOXES.phone}
                                        rules={[{
                                            required: true, pattern: /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/g,
                                            message: 'Номер телефона должен соотвествовать форме +79XXXXXXXXX из цифр'
                                        }]}
                                        type={['text']}
                                        info={[user.phone || '']}
                                        name={['phone']}
                                        label={['Тел.']}
                                        role={user.roles[0].role_id}
                                    />
                                    <ProfileDataBox title={PROFILE_BOXES.firm}
                                        rules={[{required: true}]}
                                        type={['text']}
                                        info={[user.company_name || '']}
                                        name={['company_name']}
                                        label={['Юр. лицо']}
                                        role={user.roles[0].role_id}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <ProfileDataBox title={PROFILE_BOXES.email}
                                        rules={[{required: true, min: 5, max: 50, type: 'email'}]}
                                        type={['email']}
                                        info={[user.email || '']}
                                        name={['email']}
                                        label={['Зл. почта']}
                                        role={user.roles[0].role_id}
                                    />
                                    <ProfileDataBox title={PROFILE_BOXES.contact}
                                        rules={[{required: true, pattern: /\d+/g, message: 'Номер телефона должен состоять из цифр'},
                                        {required: true, min: 5, max: 50, type: 'email'}]}
                                        type={['text', 'email']}
                                        info={[user.user_manager_phone || '', user.user_manager_email || '']}
                                        name={['user_manager_phone', 'user_manager_email']}
                                        label={['Тел. менеджера', 'Эл. почта менеджера']}
                                        role={user.roles[0].role_id}
                                    />
                                </Col>
                            </>}


                        </Row>
                    </div>

                </Col>
            </Row>
        </Layout>
    )
}

export default memo(ProfilePage)

// function action(action: any): any {
//     throw new Error('Function not implemented.')
// }
