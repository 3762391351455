import React, { memo } from 'react';
import './AsidePicture.scss';

const AsidePicture: React.FC = () => {

    return (
        <div className="aside-picture"></div>
    )
}

export default memo(AsidePicture)

