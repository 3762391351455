import React, { memo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'
import LoginReduxForm from '../../app/components/login-form';
import authService from '../../core/services/AuthService';
import InHeader from '../../app/components/in-header/index';
import AsidePicture from '../../app/components/aside-picture/index';
import './LoginPage.scss';
import { ROUTES } from '../../core/consts/common';
import { actions } from '../../core/store'
import userService from '../../core/services/UserService';
import { useSelector } from 'react-redux'
import { rootState } from '../../core/types/rootState'

const LoginPage: React.FC = () => {
    const id = useSelector((state: rootState) => state.auth.currentId);

    const onSubmit = (formData: any) => {
        authService.login(formData.username, formData.password)
            .then(() => {          
                actions.setRoute(ROUTES.MAIN)               
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        id && userService.getUserInfo(id)
            .then((res) => {
                actions.saveUser({
                    id: res.id,
                    role: res.roles[0].role_id,
                    name: res.name,
                    avatar_url: res.avatar_url,
                    last_name: res.last_name,
                    email: res.email,
                    balance: res.balance
                })
            })
            .catch((err) => console.log(err));
    }, [id])

    return (
        <section className="login">
            <AsidePicture />
            <div className="login__wrap">
                <InHeader />
                <h3 className="login__title">
                    Вход
                </h3>
                <p className="login__text">
                    У вас еще нет аккаунта? <Link to={ROUTES.REGISTRATION} className="login__link">Зарегистрироваться</Link>
                </p>
                <LoginReduxForm onSubmit={onSubmit} />
            </div>
        </section>
    )
}

export default memo(LoginPage);