import {useSelector} from 'react-redux';
import authApi from '../api/authApi';
import {ROUTES} from '../consts/common';
import {actions, store} from '../store';
import {initialStateAuth} from '../store/reducers/authRerucer';
import {rootState} from '../types/rootState';
import tokenService from './TokenService';
import userService from './UserService';

// const type introspect


class AuthService {
    login(userName: string, password: string) {
        return authApi.auth(userName, password)
            .then((data: any) => {
                tokenService.setAccessToken(data.access_token);
                tokenService.setRefreshToken(data.refresh_token);
                actions.fetchAuth({...data, isAuth: true, currentId: tokenService.parseJwt(data.access_token).nameid});
            })
            .catch((error) => {
                console.log(error);
            })
    }
    logout() {
        tokenService.removeRefreshToken();
        tokenService.removeAccessToken();
        actions.setRoute(ROUTES.LOGIN);
        actions.fetchAuth({...initialStateAuth});
        //добавить успех/неудача
    }
    refreshLogin() {
        return authApi.refreshAuth()
            .then((data: any) => {
                tokenService.setRefreshToken(data.refresh_token);
                tokenService.setAccessToken(data.access_token);
                actions.fetchAuth({...data, isAuth: true, currentId: tokenService.parseJwt(data.access_token).nameid})
                userService.getUserInfo(tokenService.parseJwt(data.access_token).nameid)
                    .then((res) => {
                        actions.saveUser({
                            id: res.id,
                            role: res.roles[0].role_id,
                            name: res.name,
                            avatar_url: res.avatar_url,
                            last_name: res.last_name,
                            email: res.email,
                            balance: res.balance
                        })
                    })
                    .catch((err) => console.log(err));
            })
            .catch((error) => {console.log(error)})
    }
}

const authService = new AuthService();

export default authService;