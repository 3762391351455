export const PROFILE_BOXES = {
    name: 'Фамилия',
    age: 'Возраст',
    phone: 'Телефон',
    source: 'Источник',
    sum: 'Сумма',
    geo: 'ГЕО',
    additionalNum: 'Доп. номер для связи',
    comment: 'Комментарий',
    sex: 'Пол',
    diapason: 'Диапазон',
}
