import React from 'react';
import ReactDOM from 'react-dom';
import App from './core/App';
import {Provider} from 'react-redux';
import {store} from './core/store';
import {HashRouter} from 'react-router-dom';
import ConnectedRouter from './core/components/ConnectedRouter';

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <ConnectedRouter>
                <App />
            </ConnectedRouter>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);
