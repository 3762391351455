import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Card, Input, Form, Typography, Button } from 'antd'
import Meta from 'antd/lib/card/Meta'
import React, { memo, useEffect, useState } from 'react'
import './PurchaseDataBox.scss';
import purchaseService from '../../../core/services/PurchaseService';
import { validateAntDesignMessages } from '../../../core/utils/validators';

type PropsType = {
    editBody: queryBodyObjType,
    info: string,
    title: string,
    label: string,
    type: string,
    name: string,
    rules: object[]
}

type queryBodyObjType = {
    [key: string]: string
}

const PurchaseDataBox: React.FC<PropsType> = (props) => {
    const [isInputOpen, setIsInputOpen] = useState(false);
    const [infoValue, setInfoValue] = useState<string>(props.info);

    useEffect(() => {
        setInfoValue(props.info)
    }, [props.info])

    const onChangeInput = async (e: any) => {
        setInfoValue(e.target.value);
    }

    const handleSubmit = () => {
        setIsInputOpen(!isInputOpen);
        let obj: queryBodyObjType = props.editBody
        obj[props.name] = infoValue
        purchaseService.updatePurchaseInfo(obj)
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Card className="card__box" bordered={false}>
            <Meta
                description={props.title}
                avatar={<EditOutlined key="edit" onClick={() => setIsInputOpen(!isInputOpen)} />}
                style={{ marginBottom: "10px" }}
            />
            <div className={`${isInputOpen ? "card__info-wrap card__info-wrap_column" : "card__info-wrap"}`}>
                {isInputOpen ?
                    (<Form className="card__info-wrap"
                        onFinishFailed={onFinishFailed}
                        onFinish={handleSubmit}
                        validateMessages={validateAntDesignMessages}>
                        <Form.Item name={props.name} initialValue={props.info} rules={props.rules} label={props.label} >
                            <Input type={props.type} allowClear onChange={onChangeInput} />
                        </Form.Item>
                        <Button type="primary" htmlType="submit"
                            style={{
                                margin: '0 10px',
                                background: 'transparent',
                                color: '#000',
                                border: 'none',
                                outline: 'none',
                                padding: '0'
                            }}>
                            <CheckCircleOutlined style={{ fontSize: '30px' }} />
                        </Button>
                    </Form >)
                    :
                    (<Typography style={{ marginRight: '5%' }}>{infoValue}</Typography>)
                }
            </div>
        </Card>
    )
}

export default memo(PurchaseDataBox);