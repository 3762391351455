import React, { memo } from 'react'
import { Col, Layout, Row, Typography } from 'antd'
import MainHeader from '../../app/components/header'
import NavigationMenu from '../../app/components/navigation-menu'
import ToolsTabs from '../../app/components/tools-tabs/ToolsTabs'
import './Tools.scss';

const Tools: React.FC = () => {
    return (
        <Layout className="page">
            <Row className='page__wrap' gutter={[40, 8]} >
                <Col className='page__wrap page__wrap_menu' sm={4}>
                    <NavigationMenu />
                </Col>
                <Col className='page__wrap page__wrap_table' xs={24} sm={20}>
                    <MainHeader />
                    <div className='tools'>
                        <Typography.Title className='tools_title' level={2}>
                            Инструменты</Typography.Title>
                        <ToolsTabs />
                    </div>
                </Col>
            </Row>
        </Layout>
    )
}

export default memo(Tools)