import {http} from '../infrastructure/Https';
import tokenService from '../services/TokenService';

const ROOT_URL = '/api/v1';

type IntrospectRequestType = {refresh_token: string}
type ConfirmSmsBodyType = {
    userId: string,
    code: string,
}


class AuthApi {
    auth(phone: string, password: string) {
        const body = {phone, password}
        return http.post(`${ROOT_URL}/auth`, null, body);
    }
    refreshAuth() {
        const body = {refresh_token: tokenService.getRefreshToken()} ;
        const accessToken = tokenService.getAccessToken();
        return http.post(`${ROOT_URL}/auth/refresh`, null, body, accessToken);
    }
    logout() {
        return http.post(`${ROOT_URL}/auth/logout`);
    }
    introspect() {
        const accessToken = tokenService.getAccessToken();
        return http.post<undefined, undefined, IntrospectRequestType>(`${ROOT_URL}/introspect`, undefined, undefined, accessToken)
    }
    confirmSms(B: ConfirmSmsBodyType ) {
        return http.post<undefined, ConfirmSmsBodyType, unknown>(`${ROOT_URL}/auth/confirm`, undefined, B, undefined);
    }
};


const authApi = new AuthApi();
export default authApi;