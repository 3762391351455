import {http} from '../infrastructure/Https';
import tokenService from '../services/TokenService';

const ROOT_URL = '/api/v1/user';
const ROOT_URL_SHORTEN = '/api/v1';


class UserApi {
    changePassword(id: string, password: string) {
        const body = {id, password};
        const accessToken = tokenService.getAccessToken();
        return http.post(`${ROOT_URL}/change_password`, null, body, accessToken);
    }
    block(id: string) {
        const body = {id, block: true};
        const accessToken = tokenService.getAccessToken();
        return http.put(`${ROOT_URL}/block`, null, body, accessToken);
    } 
    // addUser(data: object) {
    //     const accessToken = tokenService.getAccessToken();
    //     return http.post(ROOT_URL, null, data, accessToken)
    // }
    delete(id: string) {
        const body = {id};
        const accessToken = tokenService.getAccessToken();
        return http.delete(ROOT_URL, null, body, accessToken);
    }
     editUser(data: object) {
        const accessToken = tokenService.getAccessToken();
        return http.put(ROOT_URL, null, data, accessToken)
    }
    getInfo(id: string ) {
        const accessToken = tokenService.getAccessToken();        
        return http.get(ROOT_URL, {id}, accessToken);
    }
    getUsersList(Q = {}) {
        const accessToken = tokenService.getAccessToken();
        return http.get(`${ROOT_URL}/list`, Q, accessToken);
    }
    getSellInfo(Q = {} ) {
        const accessToken = tokenService.getAccessToken();        
        return http.get(`${ROOT_URL}_stats/sell`, Q, accessToken);
    }
    getRolesList() {
        const accessToken = tokenService.getAccessToken();        
        return http.get(`${ROOT_URL_SHORTEN}/roles`, null, accessToken);
    }
};


const userApi = new UserApi();
export default userApi;