import React, { memo } from "react";
import { Form, InputNumber, Button } from 'antd';
import operationsService from "../../../core/services/OperationsService";
import './TopUpBalance.scss';

type PropsType = {
    id: string,
    setInvisible: any
}

const TopUpBalance: React.FC<PropsType> = (props) => {

    const handleSubmit = (event: any) => {
        operationsService.topUpBalanceService({
            user_id: props.id,
            amount: event.amount,
            meta: {
                datetime: '2005-08-09T18:31:42',
                comment: 'no comments'
            }
        })
            .then((data) => {
                if (data) props.setInvisible(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <Form
            className="topup-balance__form"
            name="topUpBalance"
            onFinish={handleSubmit}
        >
            <p style={{ padding: '25px 0' }}>Введите в поле ниже сумму, которую вы хотите внести. Вы можете вывести деньги обратно на карту в любое удобное время.</p>
            <Form.Item
                name="amount"
                rules={[{ required: true, message: 'Пожалуйста, введите сумму' }]}
            >
                <InputNumber
                    style={{ backgroundColor: '#fff', minWidth: '80%', width: 'fit-content', color: '#444853', lineHeight: '3', height: 'auto', }}
                    placeholder={'Сумма'}
                />
            </Form.Item>
            <Form.Item >
                <Button type="primary" htmlType="submit"
                    style={{ backgroundColor: '#444853', minWidth: '80%', margin: '0 auto 0 0', width: 'fit-content', color: '#fff', lineHeight: '3', height: 'auto', textAlign: 'center' }}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default memo(TopUpBalance)