import {RegistrationFormType} from '../../pages/registration/types';
import {http} from '../infrastructure/Https';

const ROOT_URL = '/api/v1';
export type RegistrationResponseType = {
    id: string
}

class RegistrationApi {
    registration({...props}: RegistrationFormType) {
        const body = {...props};
        return http.post<undefined, RegistrationFormType, RegistrationResponseType | Error>(`${ROOT_URL}/register`, undefined, body);
    }
};


const registrationApi = new RegistrationApi();
export default registrationApi;