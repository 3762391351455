import {Button, Result} from 'antd';
import React, {memo} from 'react';

const NotFoundPage: React.FC = () => {
    return (
        <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
        <Button type="primary"
        href="/">Back Home</Button>
    }
      />
    );
};

export default memo(NotFoundPage);
