import {combineReducers} from 'redux';
import { reducer as formReducer} from 'redux-form';
import {authReducer} from './authRerucer';
import {registrationReducer} from './registrationReducer';
import {userReducer} from './userReducer';
import {rootState} from '../../types/rootState';
import {routeReducer} from './routeReducer';



export const rootReducer = combineReducers<rootState>({
    form: formReducer,
    auth: authReducer,
    registration: registrationReducer,
    user: userReducer,
    route: routeReducer,
})

export type RootState = ReturnType<typeof rootReducer>