import React, { useState, useEffect, memo } from "react";
import { Table, Button, Typography, Input, Select } from "antd";
import { RightOutlined } from '@ant-design/icons';
import { ROUTES } from "../../../core/consts/common";
import purchaseSerice from "../../../core/services/PurchaseService";
import { routeService } from "../../../core/services/RouteService";
import './MyClientsTable.scss';

const MyClientsTable: React.FC = () => {

    const columns = [
        {
            title: 'Дата',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'date'
        },
        {
            title: 'ГЕО',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'order',
            render: (order: { region: string }) =>
                <span>{order.region}</span>

        },
        {
            title: 'Цена мин',
            dataIndex: "order",
            ellipsis: {
                showTitle: true,
            },
            render: (order: { price: { min: number } }) =>
                <span>{order.price.min}</span>
        },
        {
            title: 'Цена мах',
            dataIndex: "order",
            ellipsis: {
                showTitle: true,
            },
            render: (order: { price: { max: number } }) =>
                <span>{order.price.max}</span>
        },
        {
            title: 'Возраст',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'order',
            render: (order: { age: number }) =>
                <span>{order.age}</span>
        },
        {
            title: 'Источник',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'order',
            render: (order: { source: string }) =>
                <span>{order.source}</span>
        },
        {
            title: 'Телефон',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'order',
            render: (order: { phone: string }) =>
                <span>{order.phone}</span>
        },
        {
            title: 'Фамилия',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'order',
            render: (order: { fam: string }) =>
                <span>{order.fam}</span>
        },
        {
            title: '',
            dataIndex: "id",
            render: (id: string) => (
                <Button
                    id={`${id}`}
                    onClick={handleProfilePageClick}
                    style={{ outline: 'none', border: 'none', color: '#1890ff' }}>
                    Профиль <RightOutlined />
                </Button>
            )
        },
    ]

    const [tableData, setTableData] = useState<any[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [tablePagination, setTablePagination] = useState<number>(0);
    const [tablePageSize, setTablePageSize] = useState<any>(10);
    const [tableCurrent, setTableCurrent] = useState<any>(1);

    const onSelectChange = (selectedRows: any[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRows);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const getSelectedLeads = () => {
        !!selectedRowKeys.length && alert(selectedRowKeys[0])
    }
    
    const handleProfilePageClick = (event: any) => {
        routeService.pushWithQuery(ROUTES.CLIENT_PAGE, {
            id: event.currentTarget.id
        },
            {
                shouldRefresh: true
            })
    }

    useEffect(() => {
        let Q = {
            dtstart: '210621',
            offset: tablePageSize * (tableCurrent - 1),
            count: tablePageSize
        };
        let tmp: any;
        purchaseSerice.getPurchasesList(Q)
            .then((res) => {
                tmp = res
                setTableData(tmp.result.map((lead: any) => {
                    return { ...lead, key: lead.id }
                }))
                setTablePagination(tmp.pagination.total)
            })
            .catch((err) => console.log(err));
    }, [])

    return (
        <div className="my-clients-table">
            <Typography.Title level={2}>Мои клиенты</Typography.Title>
            {/* <div className="my-clients-table__queries">
                <Input style={{ width: '412px', height: '50px' }} />
                <Select style={{ width: '231px', height: '50px', margin: '0 16px' }} />
                <Select style={{ width: '231px', height: '50px' }} />
            </div> */}
            <Table
                pagination={{
                    total: tablePagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', tablePagination.toString()],
                }}
                onChange={e => {
                    setTablePageSize(e.pageSize);
                    setTableCurrent(e.current)
                }}
                scroll={{ x: '80vw', y: '40vh' }}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData}
            />
            <Button onClick={getSelectedLeads}
                style={{ backgroundColor: '#444853', minWidth: '15vw', margin: '0 30px 0 auto', width: 'fit-content', color: '#fff', lineHeight: '3', height: 'auto', borderRadius:'6px', textAlign: 'center' }}>
                Выгрузить ({selectedRowKeys.length})
            </Button>
        </div>
    )
}

export default memo(MyClientsTable)