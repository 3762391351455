import purchasesApi from '../api/purchasesApi';
import introspectService from './IntropsectService'

class PurchaseService {
    getPurchasesList(date: {}){
        return purchasesApi.getPurchasesList(date)
        .then((res: any) => {
            return res;
        })
        .catch((error) => {
            introspectService.checkToken(error.response.status.toString());
            console.log(error);
        })
    }
    getPurchaseInfo(id: string) {
        return purchasesApi.getInfo(id)
            .then((data: any) => {
                return data;
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }
    updatePurchaseInfo(data: object) {
        return purchasesApi.editInfo(data)
            .then((data: any) => {
                return data;
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }
    getSelectedLeads(data: any[]) {
        return purchasesApi.getSelectedLeadCSV(data)
            .then((data: any) => {
                return data;
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }
}

const purchaseSerice = new PurchaseService();
export default purchaseSerice;