import React, { memo } from 'react';
import {Link} from 'react-router-dom'
import './RegistrationOptions.scss';
import { Button, Space } from 'antd';
import { BUTTONS } from '../../enum';
import {ROUTES} from '../../../core/consts/common';
import {actions} from '../../../core/store';

const RegistrationOptions: React.FC = (props: any) => {
    const handleClick = (type: string): void => {
        actions.SetRegistrationType(type);
    }

    return (
        <div className="registration-options">
            <p className="registration-options__text">
                Как вы хотите пользоваться сервисом?
            </p>
            <Space direction="vertical" size={15}>
                <Button onClick={() => handleClick('advertiser')} 
                className="registration-options__button" 
                style={{ width: 415, height: 50 }}>
                    {BUTTONS.registrationOptionsPromouter}
                </Button>
                <Button onClick={() => handleClick('webmaster')}
                className="registration-options__button" 
                style={{ width: 415, height: 50 }}>
                    {BUTTONS.registrationOptionsSupplier}
                </Button>
            </Space>
            <Link className="registration-options__link" to={ROUTES.LOGIN}>У меня уже есть аккаунт</Link>
        </div>
    )
}

export default memo(RegistrationOptions)