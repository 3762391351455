import userApi from '../api/userApi';
import introspectService from './IntropsectService';

class UserService {
    getUserInfo(id: string) {
        return userApi.getInfo(id)
            .then((data: any) => {              
                return data;
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }
    updateUserInfo(data: object) {
        return userApi.editUser(data)
            .then((data: any) => {               
                return data;
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }
    getFullUsersList(Q: object) {
        return userApi.getUsersList(Q)
            .then((data: any) => {               
                return data;
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }
    getRolesListService() {
        return userApi.getRolesList()
            .then((data: any) => {               
                return data;
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }
}

const userService = new UserService();
export default userService;