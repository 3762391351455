import {Layout, Row, Col} from 'antd';
import React, {memo} from 'react';
import MainHeader from '../../app/components/header';
import NavigationMenu from '../../app/components/navigation-menu';
const MainPage: React.FC = () => {

    return (  
       <Layout className='page' >  
            <Row style={{minHeight: '100%'}}  gutter={[40, 8]} >
                <Col className='page__wrap' sm={4}>                    
                    <div className='page__wrap page__wrap_menu'>
                        <NavigationMenu />
                    </div>
                </Col>
                <Col className='page__wrap page__wrap_table' xs={24} sm={20}>
                <MainHeader />
                </Col>
            </Row>
        </Layout>
       );
};

export default memo(MainPage);
