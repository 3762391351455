import React, { memo } from 'react'
import { Col, Layout, Row } from 'antd'
import MainHeader from '../../app/components/header'
import NavigationMenu from '../../app/components/navigation-menu'
import './MyClients.scss';
import MyClientsTable from '../../app/components/my-clients-table/MyClientsTable';

const MyClients: React.FC = () => {
    
    return (
        <Layout className="page">
            <Row className='page__wrap' gutter={[40, 8]} >
                <Col className='page__wrap page__wrap_menu' sm={4}>
                    <NavigationMenu />
                </Col>
                <Col className='page__wrap page__wrap_table' xs={24} sm={20}>
                <MainHeader />
                    <MyClientsTable />
                </Col>
            </Row>
        </Layout>
    )
}

export default memo(MyClients)





