import React, { memo, useState, useEffect } from 'react';
import { Col, Layout, Row, Typography, Button, Modal, Form, Input } from 'antd';
import './ToolsTabsContent.scss';
import { v4 } from 'uuid';
import { SMS, IVR, Viber } from './consts';

type PropsType = {
    tabType: string
}



const ToolsTabsContent: React.FC<PropsType> = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values: any) => {
        setIsModalVisible(false);
        console.log('Success:', values);
      };
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
    return (
        <Layout className='tools__tab-content'>
            <Row gutter={35}>
                <Col xs={24} sm={12} md={8}>
                    <Typography.Title level={4}>
                        Описание
                    </Typography.Title>
                    <Typography.Text>
                        {props.tabType === 'SMS' ?
                            SMS.description : props.tabType === 'IVR' ?
                                IVR.description : Viber.description}
                    </Typography.Text>
                    <Typography.Title level={4}>
                        Способы применения
                    </Typography.Title>

                    <ul>
                        {
                            (props.tabType === 'SMS' ?
                                SMS.using : props.tabType === 'IVR' ?
                                    IVR.using : Viber.using).map((item) => {
                                        return (<li key={v4()}>
                                            {item}
                                        </li>)
                                    })
                        }
                    </ul>
                </Col>
                <Col xs={24} sm={12} md={8} className='tools__side-col'>
                    <Typography.Title level={4}>
                        Стоимость
                    </Typography.Title>
                    {(props.tabType === 'SMS' ?
                        SMS.price : props.tabType === 'IVR' ?
                            IVR.price : Viber.price).map((item: {}) => {
                                return (
                                    <span  key={v4()}>
                                        <Typography.Text className='tools__price-text'>{Object.keys(item)}</Typography.Text>
                                        <Typography.Text className='tools__price-digits'>{Object.values(item)}</Typography.Text>
                                    </span>
                                )
                            })
                    }
                    <Button onClick={showModal}
                        style={{ backgroundColor: '#444853', borderRadius: '6px', minWidth: '15vw', margin: '20% 0 0', width: 'fit-content', color: '#fff', lineHeight: '3', height: 'auto', textAlign: 'center' }}>
                        Подключить</Button>
                </Col>
            </Row>
            <Modal
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <Typography.Title level={3}
                    style={{ textAlign: 'center', display: 'block' }}
                >Подключить услугу</Typography.Title>
                <Typography.Text
                    style={{ textAlign: 'center', display: 'block', margin: '10px 0' }}
                >Пожалуйста, проверьте свои контактные данные, наш менеджер свяжется с вами в ближайшее время для подключения услуги</Typography.Text>
                <Form
                    name="toolsForm"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        style={{ display: 'block' }}
                        label="Имя"
                        name="username"
                        rules={[{ required: true, message: 'Пожалуйста, введите Ваше Имя' }]}
                    >
                        <Input
                            type={'text'}
                            style={{ display: 'block' }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'block' }}
                        label="Номер телефона"
                        name="Phone"
                        rules={[{
                            required: true, pattern: /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/g,
                            message: 'Номер телефона должен соотвествовать форме +79XXXXXXXXX из цифр'
                        }]}>
                        <Input
                            type={'tel'}
                            placeholder='+7 (900) 000-00-00'
                            style={{ display: 'block' }}
                        />
                    </Form.Item>
                    <Form.Item>
                    <Typography.Text style={{ textAlign: 'center', display: 'block', margin: '10px 0' }}>Свяжитесь с менеджером</Typography.Text>
                    <Button style={{ backgroundColor: '#444853', borderRadius: '6px', display: 'block', margin:'0 auto', padding: '0 55px', width: 'fit-content', color: '#fff', lineHeight: '3', height: 'auto', textAlign: 'center' }}
                        type="primary" htmlType="submit">
                        Жду звонка
                    </Button>
                </Form.Item>
                </Form>
            </Modal>
        </Layout>
    )
}

export default memo(ToolsTabsContent)