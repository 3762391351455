import React, { memo, useState, useEffect } from 'react';
import { Tabs, Col, Layout, Row, Typography } from 'antd';
import './AdminTabs.scss';
import { v4 } from 'uuid';
import ProfileDataBox from '../profile-data-box/ProfileDataBox';
import PersonalAccountSupplerData from '../personal-account-supplier-data/PersonalAccountSupplerData';
import { PROFILE_BOXES } from '../../../pages/profile/constans';
import userService from '../../../core/services/UserService';
import TopUpBalance from '../top-up-balance/TopUpBalance';
const { TabPane } = Tabs;


type PropsType = {
    id: string,
    setInvisible: any
}

interface User {
    id: string;
    name: string;
    family_name: string;
    last_name: string;
    email: string;
    phone: string;
    balance: number;
    birth_date: string;
    roles:any[];
}


const initialUser: User = {
    id: '',
    name: "",
    family_name: "",
    last_name: "",
    email: "",
    phone: "",
    balance: 0,
    birth_date: "",
    roles:['']
}

const AdminTabs: React.FC<PropsType> = (props) => {
    const [user, setUser] = useState<User>(initialUser)
    useEffect(() => {
        let tmp: any;
        userService.getUserInfo(props.id)
            .then((res) => {
                tmp = res;
                setUser(tmp)
            })
            .catch((err) => console.log(err));
    }, [props.id])

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Данные пользователя" key={v4()}>
                    <Typography.Title level={4}>Личные данные</Typography.Title>
                    <Layout>
                        <Row justify='space-between' gutter={30}>
                            <Col xs={24} md={12}>
                                <ProfileDataBox
                                    rules={[{ required: true, min: 1, max: 50 }
                                        , { required: true, min: 1, max: 50 }
                                        , { required: true, min: 1, max: 50 },]}
                                    title={PROFILE_BOXES.name}
                                    info={[user.name || '', user.family_name || '', user.last_name || '']}
                                    type={['text']}
                                    name={['name', 'family_name', 'last_name']}
                                    label={['Имя', 'Отчество', 'Фамилия']}
                                    role={user.roles[0].role_id}
                                    id={ user.id}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <ProfileDataBox title={PROFILE_BOXES.date}
                                    rules={[{ required: true }]}
                                    isDate={true}
                                    type={['date']}
                                    info={[user.birth_date || '']}
                                    name={['birth_date']}
                                    label={['Дата рождения']}
                                    role={user.roles[0].role_id}
                                    id={ user.id}
                                />
                            </Col>
                        </Row>
                        <Typography.Title style={{backgroundColor: '#fff', padding: '2vh 0'}} level={4}>Контактные данные</Typography.Title>
                        <Row justify='space-between' gutter={[30, 50]}>
                            <Col xs={24} md={12}>
                                <ProfileDataBox title={PROFILE_BOXES.phone}
                                    rules={[{
                                        required: true, pattern: /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/g,
                                        message: 'Номер телефона должен соотвествовать форме +79XXXXXXXXX из цифр'
                                    }]}
                                    type={['text']}
                                    info={[user.phone || '']}
                                    name={['phone']}
                                    label={['Тел.']}
                                    role={user.roles[0].role_id}
                                    id={ user.id}
                                />

                            </Col>
                            <Col xs={24} md={12}>
                                <ProfileDataBox title={PROFILE_BOXES.email}
                                    rules={[{ required: true, min: 5, max: 50, type: 'email' }]}
                                    type={['email']}
                                    info={[user.email || '']}
                                    name={['email']}
                                    label={['Зл. почта']}
                                    role={user.roles[0].role_id}
                                    id={ user.id}
                                />

                            </Col>
                        </Row>
                    </Layout>
                </TabPane>
                <TabPane tab="Траты" key={v4()}>
                    <PersonalAccountSupplerData
                        user_id={user.id}
                    />
                </TabPane>
                <TabPane tab="Пополнение баланса" key={v4()}>
                    <TopUpBalance
                        setInvisible={props.setInvisible}
                        id={user.id}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default memo(AdminTabs)