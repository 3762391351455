import {http} from '../infrastructure/Https';
import tokenService from '../services/TokenService';
const ROOT_URL = '/api/v1/orders/';

class OrdersApi {
    getAutoData(Q = {}){
        const accessToken = tokenService.getAccessToken();
        return http.get(`${ROOT_URL}auto/list`, null, accessToken);
    }
    getFinanceData(Q = {}){
        const accessToken = tokenService.getAccessToken();
        return http.get(`${ROOT_URL}finance/list`, Q, accessToken);
    }
    getRealtyData(Q = {}){
        const accessToken = tokenService.getAccessToken();
        return http.get(`${ROOT_URL}realty/list`, null, accessToken);
    }
}

const ordersApi = new OrdersApi();
export default ordersApi;