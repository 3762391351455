export enum BUTTONS {
    headerMyClient = 'Мои клиенты',
    headerFindClients = 'Найти клиентов',
    findClientsAutoBuy = 'Автопокупка',
    findClientsBuy = 'Купить',
    findClientsNewSearch = 'Новый поиск',
    registrationOptionsPromouter = 'Я рекламодатель',
    registrationOptionsSupplier = 'Я поставщик',
    registrationOptionsContinue = 'Продолжить',
    registrationOptionsSubmit = 'Зарегистрироваться',
    login = 'Войти',
    registrationStageFirst = 'Продолжить',
    registrationStageSecond = 'Зарегистрироваться',
}

export enum LOGIN_FORM {
    labelUsername = 'Номер телефона',
    placeholderUsername = '+7 (___)___-__-__',
    labelPassword = 'Пароль',
    placeholderPassword = 'Ваш пароль',
    rememberMe = 'Запомнить меня',
    login = 'Войти'
}

export enum REGISTRATION_FORM_ONE {
    labelUserLastName = 'Фамилия',
    placeholderUserLastName = 'Ваша фамилия',
    labelUserFirstName = 'Имя',
    placeholderUserFirstName = 'Ваше имя',
    labelUserPatrName = 'Отчество',
    placeholderUserPatrName = 'Ваше отчество',
    labelUserBirthDate = 'Дата рождения',
    placeholderUserBirthDate = 'дд.мм.гггг',
    labelUserPhone = 'Номер телефона',
    placeholderUserPhone = '+7 (___)___-__-__',
    labelUserEmail = 'Электронная почта',
    placeholderUserEmail = 'Адрес электронной почты',
}

export enum REGISTRATION_FORM_TWO {
    labelUserManagerPhone = 'Телефон',
    placeholderUserManagerPhone = 'Телефон менеджера',
    labelUserManagerEmail = 'Электронная почта',
    placeholderUserManagerEmail = 'Почта менеджера',
    labelUserCompanyName = 'Ваша компания',
    placeholderUserCompanyName = 'Название вашей организации',
    labelUserPassword = 'Пароль',
    placeholderUserPassword = 'Придумайте пароль',
    labelUserAgreement = 'Я согласен с условиями сервиса и политикой персональных данных',
}

// export enum NAVIGATION_DATA_TEXT {
//     Profile = 'Профиль',
//     Statistics = 'Статистика',
//     BaseUpload = 'Загрузка базы',
//     FAQ = 'FAQ',
//     About = 'О нас',
//     Stock = 'Биржа',
//     AutoPurchase = 'Автопокупка',
//     Tools = 'Инструменты',
//     labelUserAgreement = 'Я согласен с условиями сервиса и политикой персональных данных',
// }

export enum FIND_CLIENTS_FILTER {
    labelSex = 'Пол:',
    placeholderSex = 'Выберите пол', 
    labelAgeMin = 'Возраст минимальный:',
    placeholderAgeMin = 'Выберите возраст',
    labelAgeMax = 'Возраст максимальный:',
    placeholderAgeMax = 'Выберите возраст',
    labelExclusivity = 'Эксклюзивность заявки:',
    labelPurchasesNumber = 'Количество покупок:',
    labelRegion = 'Регион:',
    placeholderRegion = 'Выберите регион',
    labelPriceMin = 'Минимальная стоимость:',
    labelPriceMax = 'Мaксимальная стоимость:',
    submitButton = 'Применить',
    resetButton = 'Сбросить фильтры',
}