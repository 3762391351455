import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import mainPageRouter from '../pages/main/routing';
import findClientsPageRouter from '../pages/find-clients/routing';
import NotFoundPage from '../pages/not-found/NotFound';
import RegistrationRouter from '../pages/registration/routing';
import loginPageRouter from '../pages/login/routing';
import profileRouter from '../pages/profile/routing';
import PersonalAccountSuppler from '../pages/personal-account-suppier/routing';
import StockExchange from '../pages/stock-exchange/routing';
import MyClients from '../pages/my-clients/routing';
import AdminPage from '../pages/admin-page/routing';
import './App.scss';
import ClientPage from '../pages/client-page/routing';
import Tools from '../pages/tools/routing';
import tokenService from './services/TokenService';
import authService from './services/AuthService';
import {useSelector} from 'react-redux';
import {authSelector} from './selectors/selectors';
import {routeService} from './services/RouteService';
import {ROUTES} from './consts/common';


const App: React.FC = () => {
    const isAuth = useSelector(authSelector)  
    useEffect(() => {
        const token = tokenService.getRefreshToken();
        if (token) {
            authService.refreshLogin()
                .then((res) => console.log(res))
                .catch((e) => console.log(e))
        }
        if (!isAuth) {
            routeService.push(ROUTES.LOGIN);
        }
    }, []);
    

    return (
        <Switch>
            {loginPageRouter}
            {RegistrationRouter}
            {isAuth && {...mainPageRouter}}
            {isAuth && {...findClientsPageRouter}}
            {isAuth && {...PersonalAccountSuppler}}
            {isAuth && {...StockExchange}}
            {isAuth && {...profileRouter}}
            {isAuth && {...AdminPage}}
            {isAuth && {...ClientPage}}
            {isAuth && {...MyClients}}
            {isAuth && {...Tools}}
            {!isAuth && <Redirect to='/login' />}
            <Route>
                <NotFoundPage />
            </Route>
        </Switch>
    );
}


export default App;
