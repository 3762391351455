import { Table, Select } from 'antd';
import React, { memo } from 'react';
import { DATA } from '../../consts';
import './StockExchangeTable.scss';
const { Column } = Table;

const StockExchangeTable: React.FC = () => {

  return (
    <div className="stock-exchage-tab">
      <h3 className="stock-exchage-tab__title">
        Биржа
      </h3>
      <div className="stock-exchage-tab__queries">
                <Select style={{ width: '231px', height: '50px', margin: '0 16px' }} />
                <Select style={{ width: '231px', height: '50px' }} />
            </div>
      <Table dataSource={DATA} className="stock-exchage-tab__table"
        scroll={{ x: '100vw', y: '47vh' }}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          // pageSizeOptions: ['9','10', '20', '30'],
        }}
      >
        <Column title="Время" dataIndex="date" key="date" />
        <Column title="ГЕО" dataIndex="geolocation" key="geolocation" />
        <Column title="Сумма" dataIndex="money" key="money" />
        <Column title="Возраст" dataIndex="age" key="age" />
        <Column title="Источник" dataIndex="source" key="source" />
        <Column title="Продукт" dataIndex="product" key="product" />
        <Column title="Количество покупок" dataIndex="quantaty" key="quantaty" />
        <Column title=" " dataIndex="buy" key="buy" />
      </Table>
    </div>
  )
}

export default memo(StockExchangeTable);