import { Col, Layout, Row, Divider, Typography, Card } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import React, { memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import MainHeader from '../../app/components/header'
import NavigationMenu from '../../app/components/navigation-menu'
import PurchaseDataBox from '../../app/components/purchase-data-box'
import { PROFILE_BOXES } from './constans'
import './ClientPage.scss';
import purchaseService from '../../core/services/PurchaseService'
import { v4 } from 'uuid';

interface Lead {
    comment: string;
    phone_additional: string;
    order: {
        phone: string;
        fam: string;
        region: string;
        age: number;
        price: {
            min: number;
            max: number;
        };
    }
    // dealType: Auto | Finance | Reality;
}

type queryBodyObjType = {
    [key: string]: string
}

const ClientPage: React.FC = () => {
    const history = useHistory();

    const leadInfo: Lead = {
        comment: '',
        phone_additional: '',
        order: {
            phone: '',
            fam: '',
            region: '',
            age: 0,
            price: {
                min: 0,
                max: 0,
            }
        },
        // dealType: { }
    }
    const url: string[] = window.location.href.split('=')
    const id: string = url[url.length - 1]

    const [lead, setLead] = useState<Lead>(leadInfo)
    const [editBody, setEditBody] = useState<queryBodyObjType>({
        id: '',
        comment: '',
        phone_additional: '',
    })
    useEffect(() => {
        let tmp: any;
        purchaseService.getPurchaseInfo(id)
            .then((res) => {
                tmp = res;
                setLead(tmp)
                setEditBody({
                    id: tmp.id,
                    comment: tmp.comment,
                    phone_additional: tmp.phone_additional,
                })
            })
            .catch((err) => console.log(err));
    }, [id])

    return (
        <Layout className="page">
            <Row className='page__wrap' gutter={[40, 8]} >
                <Col className='page__wrap' sm={4}>
                    <NavigationMenu />
                </Col>
                <Col className='page__wrap page__wrap_table' xs={24} sm={20}>
                    <MainHeader />
                    <div className='client-page'>
                        <ArrowLeftOutlined onClick={() => { history.goBack() }} />
                        <Typography.Title level={2}>Профиль лида</Typography.Title>
                        <Divider />
                        <Row justify='space-between' gutter={30}>
                            <Col xs={24} md={12} style={{ backgroundColor: '#f2f2f2' }}>
                                <Card bordered={false}
                                    style={{ backgroundColor: '#f2f2f2', display: 'inline-block' }}
                                    title={PROFILE_BOXES.name} >
                                    {lead.order.fam}
                                </Card>
                                <Card bordered={false}
                                    style={{ backgroundColor: '#f2f2f2', display: 'inline-block' }}
                                    title={PROFILE_BOXES.age} >
                                    {lead.order.age}
                                </Card>
                                <Divider />
                                <Card bordered={false}
                                    style={{ backgroundColor: '#f2f2f2', display: 'inline-block' }}
                                    title={PROFILE_BOXES.geo}
                                >
                                    {lead.order.region}
                                </Card>
                                <Card title={PROFILE_BOXES.phone}
                                    style={{ backgroundColor: '#f2f2f2', display: 'inline-block' }}>
                                    {lead.order.phone}
                                </Card>
                                <Divider />
                                {Object.entries(lead.order.price).map((item) => {
                                    return <Card bordered={false} key={v4()}
                                        style={{ backgroundColor: '#f2f2f2', display: 'inline-block' }}
                                        title={item[0]}
                                    >
                                        {item[1]}
                                    </Card>
                                })}
                            </Col>
                            <Col xs={24} md={12}>
                                <PurchaseDataBox
                                    editBody={editBody}
                                    rules={[{ required: true, pattern: /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/g,
                                     message: 'Номер телефона должен соотвествовать форме +79XXXXXXXXX из цифр' }]}
                                    title={PROFILE_BOXES.additionalNum}
                                    info={lead.phone_additional}
                                    type={'text'}
                                    name={'phone_additional'}
                                    label={''}
                                />
                                <PurchaseDataBox
                                    editBody={editBody}
                                    rules={[{}]}
                                    title={PROFILE_BOXES.comment}
                                    info={lead.comment}
                                    type={'text'}
                                    name={'comment'}
                                    label={''}
                                />
                            </Col>
                        </Row>
                        {/* <Row >
                        <Button>
                            Отменить изменения (1)
                        </Button>
                        <Button>
                            Сохранить
                        </Button>
                    </Row> */}
                    </div>
                </Col>
            </Row>
        </Layout>
    )
}

export default memo(ClientPage)





