
export const validators = {
  required: (value: string) => value ? undefined : 'Обязательное поле',
  maxLength: (max: number) => (value: string) =>
    value && value.length > max ? `Минимум ${max} символов` : undefined,
  number: (value: string) => value && isNaN(Number(value)) ? 'Введите числовые значения' : undefined,
  minValue: (min: number) => (value: number) =>
    value && value < min ? `Должно быть не меньше ${min}` : undefined,
  email: (value: string) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
      'Неверный email' : undefined
}

export const validateAntDesignMessages = {
  required: '${label} - обязателное поле',
  text: {
    range: '${label} длина не менее ${min} и не более ${max}',
  },
  types: {
    email: '${label} не валидный email',
    date: '${label} не валидная дата'
  },
};