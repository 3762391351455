

export interface AuthState {
    access_token: string;
    expires_in: number;
    scope: string;
    refresh_token: string;
    token_type: string;
    isAuth: boolean;
    currentId: string;
}

export enum AuthActionTypes {
    SIGN_IN = 'SIGN_IN',
    SET_AUTH = 'SET_AUTH',
    SIGH_UP = 'SIGH_UP'
}
interface FetchSignIn {
    type: AuthActionTypes.SIGN_IN;
    payload?: any;
    data?: AuthState
}

interface FetchSetAuth {
    type: AuthActionTypes.SET_AUTH;
    payload: any[];
}

interface FetchSignUp {
    type: AuthActionTypes.SIGH_UP;
    payload: string;
}

export type AuthAction = FetchSignIn | FetchSetAuth | FetchSignUp