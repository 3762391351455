import {RegistrationAction, RegistrationActionTypes, RegistrationState} from '../../types/registration';

const initialState: RegistrationState = {
   type: null
}

export const registrationReducer = (state = initialState, action: RegistrationAction): RegistrationState => {
    switch (action.type) {
        case RegistrationActionTypes.REGISTRATION_TYPE:
            return {
                ...state,
                type: action.payload,
            }
        default:
            return state
    }
}