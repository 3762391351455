import {getFormValues} from 'redux-form';
import React, {memo} from 'react';
import {Field, reduxForm, Form} from 'redux-form';
import {FIND_CLIENTS_FILTER} from '../../enum';

const renderTextField = (field: any) => {
    return (
        <div>
            <input {...field.input} {...field} />
            {field.meta.touched && field.meta.error &&
                <span className="registration-form__error">{field.meta.error}</span>}
        </div>
    )
}

const renderSelectorField = (field: any) => {
    const {input, options} = field;

    return (
        <div>
            <select {...input} >
                {options.map((item: string) => <option>{item}</option>)}
            </select>

            {field.meta.touched && field.meta.error &&
                <span className="registration-form__error">{field.meta.error}</span>}
        </div>
    )
}

const FilterFormFindClients: React.FC = (props: any) => {
    return (
        <Form onSubmit={props.handleSubmit} className="filter">
            <label className="filter__label">{FIND_CLIENTS_FILTER.labelSex}
                <label><Field name="sex" component='input' type='radio' value="true" /> Мужчина</label>
                <label><Field name="sex" component='input' type='radio' value="false" /> Женщина</label>
            </label>

            <label className="filter__label">{FIND_CLIENTS_FILTER.labelAgeMin}
                <Field
                    className='filter__input'
                    name='age_min'
                    component={renderTextField}
                    placeholder={FIND_CLIENTS_FILTER.placeholderAgeMin}
                    type="number"
                    min='18'
                    max='80' />
            </label>
            <label className="filter__label">{FIND_CLIENTS_FILTER.labelAgeMax}
                <Field
                    className='filter__input'
                    name='age_max'
                    component={renderTextField}
                    placeholder={FIND_CLIENTS_FILTER.placeholderAgeMax}
                    type="number"
                    min='18'
                    max='80' />
            </label>
            <label className="filter__label">{FIND_CLIENTS_FILTER.labelExclusivity}
                
                    <label><Field name="is_exclusive" component='input' type='radio' value='true' /> Только эксклюзивные</label>
                    <label><Field name="is_exclusive" component='input' type='radio' value='false' /> Любые</label>
               
            </label>
            {/* <label className="filter__label">{FIND_CLIENTS_FILTER.labelRegion}
                <Field
                    name='region'
                    className='filter__input'
                    component={renderTextField}
                    placeholder={FIND_CLIENTS_FILTER.placeholderRegion}
                    type="text" />
            </label> */}
            <label className="filter__label">{FIND_CLIENTS_FILTER.labelPriceMin}
                <Field
                    name='min'
                    component={renderTextField}
                    className='filter__input'
                    min='0'
                    type="number" />
            </label>
            <label className="filter__label">{FIND_CLIENTS_FILTER.labelPriceMax}
                <Field
                    name='max'
                    min='0'
                    component={renderTextField}
                    className='filter__input'
                    type="number" />
            </label>
            <div>
                <button className='filter__button' type='submit'>{FIND_CLIENTS_FILTER.submitButton}</button>
                <button className='filter__button' type='button' onClick={() => props.reset()}>{FIND_CLIENTS_FILTER.resetButton}</button>
            </div>
        </Form>
    );
}

const FilterFindClientsReduxForm = reduxForm({
    form: 'filterFindClients',
    destroyOnUnmount: false
})(FilterFormFindClients)

export default memo(FilterFindClientsReduxForm);