import { Menu } from 'antd';
import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './NavigationMenu.scss';
import { BookOutlined, ContainerOutlined, FileTextOutlined, SettingOutlined, SmileOutlined, UserOutlined, ZoomInOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { ROUTES, ADMIN_ROLE_ID } from '../../../core/consts/common';
import { AlignLeftOutlined, CloseOutlined } from '@ant-design/icons';
import NavigationHeader from '../navigation-header/index';
import Balance from '../balance/index';
import { v4 } from 'uuid';
import { rootState } from '../../../core/types/rootState';


type Props = {};

const NavigationMenu: React.FC<Props> = () => {

    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isMob, setIsMob] = useState<boolean>((width <= 576));
    const initialUser = useSelector((state: rootState) => state.user)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setIsMob(width <= 576);
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);

    }, [width]);

    const toggleMobileNav = () => {
        width <= 576 && setIsMob(!isMob)
    }

    return (
        isMob ? <AlignLeftOutlined
            rotate={180}
            className="side-menu__mob-menu side-menu__mob-menu_open"
            onClick={() => {
                setIsMob(false)
            }}
        /> : <div>
            <NavigationHeader />
            <Balance />
            <Menu className='side-menu' onClick={toggleMobileNav}>     {width <= 576 && <CloseOutlined
                className="side-menu__mob-menu side-menu__mob-menu_close"
                onClick={toggleMobileNav}
            />}
                {initialUser.role === ADMIN_ROLE_ID &&
                    <Menu.Item className='side-menu__item' icon={<ContainerOutlined />} key={v4()}>
                        <NavLink exact to={ROUTES.ADMIN_PAGE} activeClassName="side-medu__item-active">Администрирование</NavLink>
                    </Menu.Item>
                }
                <Menu.Item className='side-menu__item' icon={<ContainerOutlined />} key={v4()}>
                    <NavLink exact to={ROUTES.PROFILE} activeClassName="side-medu__item-active">Профиль</NavLink>
                </Menu.Item>
                <Menu.Item className='side-menu__item' icon={<ContainerOutlined />} key={v4()}>
                    <NavLink exact to={ROUTES.FIND_CLIENTS} activeClassName="side-medu__item-active">Найти клиентов</NavLink>
                </Menu.Item>
                {/* <Menu.Item className='side-menu__item' icon={<SettingOutlined />} key={v4()}>
                    <NavLink exact to={ROUTES.STOCK_EXCHANGE} activeClassName="side-medu__item-active">Биржа</NavLink>
                </Menu.Item> */}
                <Menu.Item className='side-menu__item' icon={<FileTextOutlined />} key={v4()}>
                    <NavLink exact to={ROUTES.PERSONAL_ACCOUNT_SUPPLIER} activeClassName="side-medu__item-active">Статистика</NavLink>
                </Menu.Item>
                <Menu.Item className='side-menu__item' icon={<FileTextOutlined />} key={v4()}>
                    <NavLink exact to={ROUTES.MY_CLIENTS} activeClassName="side-medu__item-active">Мои клиенты</NavLink>
                </Menu.Item>
                <Menu.Item className='side-menu__item' icon={<FileTextOutlined />} key={v4()}>
                    <NavLink exact to={ROUTES.TOOLS} activeClassName="side-medu__item-active">Инструменты</NavLink>
                </Menu.Item>
                {/* <Menu.Item className='side-menu__item' icon={<BookOutlined />} key={v4()}>
                    <NavLink exact to={ROUTES.FAQ} activeClassName="side-medu__item-active">FAQ</NavLink>
                </Menu.Item>
                <Menu.Item className='side-menu__item' icon={<SmileOutlined />} key={v4()}>
                    <NavLink exact to={ROUTES.ABOUT} activeClassName="side-medu__item-active">О нас</NavLink>
                </Menu.Item> */}
            </Menu>
        </div>
    )
}

export default memo(NavigationMenu);
