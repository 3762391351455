export const ROUTES = {
    MAIN: '/',
    FIND_CLIENTS: '/find-clients',
    LOGIN: '/login',
    REGISTRATION: '/registration',    
    PROFILE: '/profile',
    TOOLS: '/tools',
    FAQ: '/faq',
    ABOUT: '/about',
    PERSONAL_ACCOUNT_SUPPLIER: '/personal-account-supplier',
    STOCK_EXCHANGE: '/stock-exchange',
    MY_CLIENTS: '/my-clients',
    ADMIN_PAGE: '/admin-page',
    CLIENT_PAGE: '/client-page'    
};

export const TOKEN_NAME = 'c2mRealtyTokenService';

export const ADMIN_ROLE_ID = "4d51dcd0-fc59-45fc-b98d-026b31888c41";