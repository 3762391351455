import {AuthAction, AuthActionTypes, AuthState} from '../../types/auth';

export const initialStateAuth: AuthState = {
    isAuth: false,
    access_token: '',
    expires_in: 0,
    scope: '',
    refresh_token: '',
    token_type: '',
    currentId: ''
}

export const authReducer = (state = initialStateAuth, action: AuthAction) => {
    switch (action.type) {
        case AuthActionTypes.SIGN_IN:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}