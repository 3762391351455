import operationsApi from "../api/operationsApi";
import introspectService from "./IntropsectService";

class OperationsService{
    makePurchaseOperation(data:object) {

        return operationsApi.makePurchase(data)
        .then((res:any) =>{
            return res
        })
        .catch((error) => {
            introspectService.checkToken(error.response.status.toString());
            console.log(error);
        })
    }
    topUpBalanceService(data:object) {
        return operationsApi.topUpBalance(data)
        .then((res:any) =>{
            return res
        })
        .catch((error) => {
            introspectService.checkToken(error.response.status.toString());
            console.log(error);
        })
    }
}

const operationsService = new OperationsService();
export default operationsService;