
import authApi from "../api/authApi";
import {ROUTES} from '../consts/common';
import {actions} from '../store';
import tokenService from "./TokenService";
import userService from './UserService';

class IntrospectService {
    checkToken(errorStatus: string) {
        if (errorStatus === '401') {
            authApi.introspect()
                .then((data) => {
                    tokenService.setRefreshToken(data.refresh_token)
                    authApi.refreshAuth()
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status.toString() === '404') {
                        actions.setRoute(ROUTES.LOGIN);
                    }
                })
        }
    }
}

const introspectService = new IntrospectService();
export default introspectService;