import {Button, Space} from 'antd';
import {Header} from 'antd/lib/layout/layout';
import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../../core/consts/common';
import {authSelector} from '../../../core/selectors/selectors';
import authService from '../../../core/services/AuthService';
import {BUTTONS} from '../../enum';
import './MainHeader.scss';


type Props = {};

const MainHeader: React.FC<Props> = () => {
    const isAuth = useSelector(authSelector)  
    const handleLogout = () => {
        authService.logout();
    }
    return (
        <Header className='main-header' style={{backgroundColor: "#E5E5E5", boxSizing: 'border-box', margin: '30px 0 10px'}}>
            <div className='main-header__right-box'>
                <Space size={30}>
                    {isAuth && <Button onClick={handleLogout} className='main-header__button main-header__button_logout'>Выйти</Button>}
                    <Link to={ROUTES.MY_CLIENTS}>
                        <Button className='main-header__button'>{BUTTONS.headerMyClient}</Button>
                    </Link>
                    <Link to={ROUTES.FIND_CLIENTS}>
                        <Button
                            className='main-header__button main-header__button-right'
                        >{BUTTONS.headerFindClients}</Button>
                    </Link>
                </Space>
            </div>
        </Header>
    )
}

export default memo(MainHeader);