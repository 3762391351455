import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Card, Input, Form, Typography, Button, DatePicker } from 'antd'
import Meta from 'antd/lib/card/Meta'
import React, { memo, useEffect, useState } from 'react'
import './ProfileDataBox.scss';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import userService from '../../../core/services/UserService';
import { validateAntDesignMessages } from '../../../core/utils/validators';
import { actions } from '../../../core/store'
import { useSelector } from 'react-redux'
import { rootState } from '../../../core/types/rootState'
import { ADMIN_ROLE_ID } from '../../../core/consts/common';

type PropsType = {
    id?: string,
    info: string[],
    title: string,
    label: string[],
    isDate?: boolean,
    type: string[],
    name: string[],
    rules: object[],
    role: string,
}

type queryBodyObjType = {
    [key: string]: string | null
}

const ProfileDataBox: React.FC<PropsType> = (props) => {
    const [isInputOpen, setIsInputOpen] = useState(false);
    const [infoValue, setInfoValue] = useState<string[]>(props.info);
    const user = useSelector((state: rootState) => state.user);
    const id = user.id;
    useEffect(() => {
        setInfoValue(props.info)
    }, [props.info])

    const onChangeInput = async (e: any) => {
        infoValue[e.target.id] = e.target.value;
    }

    const handleSubmit = () => {
        setIsInputOpen(!isInputOpen);
        let obj: queryBodyObjType = {}

        infoValue.forEach((item, index) => {
            obj[props.name[index]] = infoValue[index]
            obj.role = props.role
            obj['id'] = props.id || id
            obj['parent_id'] = null
        })

        userService.updateUserInfo(obj);
        if ((obj.role === ADMIN_ROLE_ID)) {
            if (!!obj.name) {
                actions.saveUser({
                    id: obj.id!,
                    role: obj.role!,
                    name: obj.name,
                    avatar_url: user.avatar_url,
                    last_name: obj.last_name!,
                    email: user.email,
                    balance: user.balance
                })
            }
            if (!!obj.email) {
                actions.saveUser({
                    id: obj.id!,
                    role: obj.role!,
                    name: user.name,
                    avatar_url: user.avatar_url,
                    last_name: user.last_name,
                    email: obj.email,
                    balance: user.balance
                })
            }
        }
    }

    const onChangeDate = (e: any) => {
        infoValue[0] = `${e._d.getDate()}.${e._d.getMonth() + 1}.${e._d.getFullYear()}`;
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Card className="card__box" bordered={false}>
            {props.name.length >= 1 ? <Meta
                description={props.title}
                avatar={<EditOutlined key="edit" onClick={() => setIsInputOpen(!isInputOpen)} />}
                style={{ marginBottom: "10px" }}
            />
                :
                props.title
            }
            <div className={`${isInputOpen ? "card__info-wrap card__info-wrap_column" : "card__info-wrap"}`}>
                {props.info.map((string, index) => (
                    isInputOpen ?
                        (<Form className="card__info-wrap" key={index} onFinishFailed={onFinishFailed} onFinish={handleSubmit} validateMessages={validateAntDesignMessages}>
                            {props.isDate ?
                                <Form.Item name={props.name[index]} rules={[props.rules[index]]} label={props.label[index]} >
                                    <DatePicker
                                        locale={locale}
                                        onChange={onChangeDate}
                                    />
                                </Form.Item>
                                :
                                <Form.Item name={props.name[index]} initialValue={string} rules={[props.rules[index]]} label={props.label[index]} >
                                    <Input id={`${index}`} type={props.type[index]} allowClear onChange={onChangeInput} />
                                </Form.Item>
                            }
                            <Button type="primary" htmlType="submit"
                                style={{
                                    margin: '0 10px',
                                    background: 'transparent',
                                    color: '#000',
                                    border: 'none',
                                    outline: 'none',
                                    padding: '0'
                                }}>
                                <CheckCircleOutlined style={{ fontSize: '30px' }} />
                            </Button>
                        </Form >)
                        :
                        (<Typography key={index} style={{ marginRight: '5%' }}>{infoValue[index]}</Typography>)
                ))}
            </div>
        </Card>
    )
}

export default memo(ProfileDataBox);