import { Col, Layout, Row, Typography } from 'antd'
import React, { memo, useState, useEffect } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import MainHeader from '../../app/components/header'
import NavigationMenu from '../../app/components/navigation-menu'
import './AdminPage.scss';
import userService from "../../core/services/UserService";
import AdminTabs from '../../app/components/admin-tabs';
import ClientCard from '../../app/components/client-card';
import { v4 } from 'uuid';
import { useSelector } from 'react-redux'
import { rootState } from '../../core/types/rootState'


type user = {
    id: string,
    email: string,
    balance: number,
    roles: rolesType,
    handleClick: any
}

type rolesType = [{
    [key: string]: string
}]


const AdminPage: React.FC = () => {
    const [isTableShown, setIsTableShown] = useState<boolean>(true);
    const [cardListData, setCardListData] = useState<user[]>([]);
    const [currentUserId, setCurrentUserId] = useState<string>(useSelector((state: rootState) => state.user.id));
    const [rolesList, setRolesList] = useState<rolesType>()
    const id = useSelector((state: rootState) => state.user.id)

    useEffect(() => {
        userService.getFullUsersList({})
            .then((data: any) => {
                if (data) {
                    setCardListData(data.result);
                }
            })
            .catch((err) => console.log(err));

    }, [isTableShown])

    useEffect(() => {
        let tmp = []
        userService.getRolesListService()
            .then((data: any) => {
                tmp = data.roles
                setRolesList(tmp)
            })
            .catch((err) => console.log(err));
    }, [])

    const handleClick = (event: any) => {
        setCurrentUserId(event.currentTarget.id)
        setIsTableShown(!isTableShown)
    }

    return (
        <Layout className="page">
            <Row className='page__wrap' gutter={[40, 8]} >
                <Col className='page__wrap page__wrap_menu' sm={4}>
                    <NavigationMenu />
                </Col>
                <Col className='page__wrap page__wrap_table' xs={24} sm={20}>
                    <MainHeader />
                    <div className='admin'>
                        {isTableShown ? <Typography.Title className='admin__title' level={2}>
                            &nbsp;&nbsp;Администрирование</Typography.Title>
                            :
                            <Typography.Title onClick={() => { setIsTableShown(true) }} className='admin__title_back' level={3}><ArrowLeftOutlined
                                style={{ fontSize: '20px' }} /> {cardListData.find(item => item.id === currentUserId)?.email} </Typography.Title>}
                        {isTableShown ? <div className="admin-table__card-list">
                            {
                                cardListData.map((user) => {
                                    return <ClientCard
                                        key={v4()}
                                        id={user.id}
                                        email={user.email || ''}
                                        balance={user.balance || 0}
                                        accountType={rolesList?.find((item) => item.role_id === user.roles[0].role_id)?.role_name || ''}
                                        handleClick={handleClick}
                                    />
                                })
                            }
                        </div>
                            :
                            <AdminTabs
                                setInvisible={setIsTableShown}
                                id={currentUserId}
                            />}
                    </div>
                </Col>
            </Row>
        </Layout>
    )
}

export default memo(AdminPage)


