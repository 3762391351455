import React, { memo, useState, useEffect } from 'react';
import './NavigationHeader.scss';
import Title from 'antd/lib/typography/Title';
import Avatar from 'antd/lib/avatar/avatar';
import Logotipe from '../../../core/images/SiteLOGO.svg';
import { useSelector } from 'react-redux'
import { rootState } from '../../../core/types/rootState'
type Props = {};

const NavigationHeader: React.FC<Props> = () => {
    const initialUser = useSelector((state: rootState) => state.user)
    const [user, setUser] = useState(initialUser)

    useEffect(() => {
        setUser(initialUser)
    }, [initialUser, user])

    return (
        <div className='navigation-header'>
            <Avatar
                className='navigation-header__avatar'
                size={70}
                src={user.avatar_url}
            />
            <Title className='navigation-header__title' level={5}>{user.name}{<br></br>}{user.last_name}</Title>
            <img src={Logotipe} className="navigation-header__logo" alt="логотип" style={{ margin: '0', padding: '0' }} />
        </div>
    )
}

export default memo(NavigationHeader);
