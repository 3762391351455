import React, { memo, useEffect, useState } from 'react';
import { Field, reduxForm, Form} from 'redux-form';
import './RegistrationForm.scss';
import { REGISTRATION_FORM_ONE, REGISTRATION_FORM_TWO, BUTTONS } from '../../enum';
import Hide from '../../../core/images/Hide';
import Show from '../../../core/images/Show';
import {validators} from '../../../core/utils/validators';
import {RegistrationFormType} from '../../../pages/registration/types';

const renderTextField = (field: any) => {
    if (field.type === 'checkbox') {
        return (
            <div>
            <input {...field.input} {...field} />
            {field.meta.touched && field.meta.error &&<span className="registration-form__error-checkbox">{field.meta.error}</span>}
        </div>
        )
    }
    return (
        <div>
            <input {...field.input} {...field} />
            {field.meta.touched && field.meta.error &&
                <span className="registration-form__error">{field.meta.error}</span>}
        </div>
    )
}


const RegistrationForm: React.FC = (props: any) => {
const [isPassowordShown, setIsPasswordShown] = useState<boolean>(false)

    return (
        <Form id='formReg' className="registration-form"
            onSubmit={props.handleSubmit}
        >
            {props[0] && <div className="registration-form__stage registration-form__stage_first">
                <p className="registration-form__text">Пожалуйста, введите свои данные.</p>
                <label className="registration-form__label">{REGISTRATION_FORM_ONE.labelUserLastName}
                    <Field 
                    className="registration-form__input" 
                    name="family_name" component={renderTextField} 
                    validate={[validators.required]}
                    placeholder={REGISTRATION_FORM_ONE.placeholderUserLastName} 
                    type="text" />
                </label>
                <label className="registration-form__label">{REGISTRATION_FORM_ONE.labelUserFirstName}
                    <Field 
                    className="registration-form__input" 
                    name="name" 
                    component={renderTextField} 
                    validate={[validators.required]}
                    placeholder={REGISTRATION_FORM_ONE.placeholderUserFirstName} 
                    type="text" />
                </label>
                <label className="registration-form__label">{REGISTRATION_FORM_ONE.labelUserPatrName}
                    <Field 
                    className="registration-form__input" 
                    name="user_patrname" 
                    validate={[validators.required]}
                    component={renderTextField} 
                    placeholder={REGISTRATION_FORM_ONE.placeholderUserPatrName} 
                    type="text" />
                </label>
                <label className="registration-form__label">{REGISTRATION_FORM_ONE.labelUserBirthDate}
                    <Field 
                    className="registration-form__input" 
                    name="birth_date" 
                    component={renderTextField} 
                    placeholder={REGISTRATION_FORM_ONE.placeholderUserBirthDate} 
                    validate={[validators.required]}
                    type="date" />
                </label>
                <label className="registration-form__label">{REGISTRATION_FORM_ONE.labelUserPhone}
                    <Field 
                    className="registration-form__input" 
                    name="phone" component={renderTextField} 
                    placeholder={REGISTRATION_FORM_ONE.placeholderUserPhone} 
                    validate={[validators.required]}
                    type="tel" />
                </label>
                <label className="registration-form__label">{REGISTRATION_FORM_ONE.labelUserEmail}
                    <Field 
                    className="registration-form__input" 
                    name="email" 
                    component={renderTextField} 
                    placeholder={REGISTRATION_FORM_ONE.placeholderUserEmail} 
                    validate={[validators.required, validators.email]}
                    type="email" />
                </label>
                <div className="registration-form__wrap">
                    <button 
                    onClick={props[2]} 
                    className="registration-form__button" 
                    type="button" >{BUTTONS.registrationStageFirst}</button>
                </div>
            </div>}
            {props[1] && <div className="registration-form__stage registration-form__stage_second">
                <p className="registration-form__text">Остался последний шаг! Введите контактные данные своего менеджера.</p>
                <label className="registration-form__label">{REGISTRATION_FORM_TWO.labelUserManagerPhone}
                    <Field 
                    className="registration-form__input" 
                    name="user_manager_phone" component={renderTextField} 
                    validate={[validators.required]}
                    placeholder={REGISTRATION_FORM_TWO.placeholderUserManagerPhone} 
                    type="text" />
                </label>
                <label className="registration-form__label">{REGISTRATION_FORM_TWO.labelUserManagerEmail}
                    <Field 
                    className="registration-form__input" 
                    name="user_manager_email" 
                    component={renderTextField} 
                    placeholder={REGISTRATION_FORM_TWO.placeholderUserManagerEmail} 
                    validate={[validators.required]}
                    type="text" />
                </label>
                <label className="registration-form__label">{REGISTRATION_FORM_TWO.labelUserCompanyName}
                    <Field 
                    className="registration-form__input" 
                    name="company_name" 
                    component={renderTextField} 
                    placeholder={REGISTRATION_FORM_TWO.placeholderUserCompanyName} 
                    validate={[validators.required]}
                    type="text" />
                </label>
                <label className="registration-form__label">{REGISTRATION_FORM_TWO.labelUserPassword}
                    <Field 
                    className="registration-form__input" 
                    name="password" 
                    validate={[validators.required, validators.minValue(6)]}
                    component={renderTextField} 
                    placeholder={REGISTRATION_FORM_TWO.placeholderUserPassword} 
                    type={`${isPassowordShown?"text":"password"}`} />
                    <span onClick={()=>{setIsPasswordShown(!isPassowordShown)}} className="registration-form__eye">{isPassowordShown?<Show/>:<Hide/>}</span>
                </label>
                <label className="registration-form__label registration-form__label_checkbox">
                    <Field 
                    className="registration-form__checkbox" 
                    name="userAgreement" 
                    component={renderTextField} 
                    type="checkbox" 
                    validate={[validators.required]} />
                    {REGISTRATION_FORM_TWO.labelUserAgreement}
                </label>
                <div className="registration-form__wrap">
                    <button 
                    className="registration-form__button" 
                    type="submit" 
                    >{BUTTONS.registrationStageSecond}</button>
                </div>
            </div>}
        </Form>
    )
}
const RegistrationReduxForm = reduxForm<RegistrationFormType>({ 
    form: 'registration'
})(RegistrationForm)
export default memo(RegistrationReduxForm)