export const DATA: any[] | undefined = [];
for (let i = 0; i < 46; i++) {
    DATA.push({
        key: i,
        date: '25 мая',
        geolocation: 'Сызрань',
        money: '399 руб',
        source: `Джон Сноу ${i}`,
        age: 32,
        product: `Чапельник металлический`,
        quantaty: `${i}`,
        buy: 'Купить'
    });
}

