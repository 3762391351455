import { Button, Space, Table, Typography } from 'antd';
import React, { useEffect, memo, useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { BUTTONS } from '../../enum';
import './FindClientsTable.scss';
import ordersApi from '../../../core/api/ordersApi';
import introspectService from '../../../core/services/IntropsectService';
import operationsService from '../../../core/services/OperationsService';
import {routeService} from '../../../core/services/RouteService';
import {EntityMode} from '../../../core/types/EntityMode';
import {ROUTES} from '../../../core/consts/common';
import TableSidebar from '../table-sidebar/TableSidebar';
import {stringParser} from '../../../core/utils/queryParsers';
import {useQuery} from '../../../core/hooks/useQuery';

const queryParsers = {
    filter: stringParser(),
    isFilter: stringParser<EntityMode>(),
    mode: stringParser<EntityMode>(),
};

const FindClientsTable: React.FC = () => {
    const columns = [
        {
            title: 'Рейтинг',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'rate',
        },
        {
            title: 'ID',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'id',
        },
        {
            title: 'Количество покупок',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'amount',
        },
        {
            title: 'Дата',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'create_date',
        },
        {
            title: 'Телефон',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'phone',
        },
        {
            title: 'Фамилия',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'fam',
        },
        {
            title: 'Пол',
            dataIndex: 'sex',
            ellipsis: {
                showTitle: true,
            },
            render: (sex: { sex: boolean }) =>
                <span>{sex ? "муж" : "жен"}</span>

        },
        {
            title: 'Возраст',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'age',
        },
        {
            title: 'ГЕО',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'region',
        },
        {
            title: 'Район',
            ellipsis: {
                showTitle: true,
            },
            dataIndex: 'district',
        },
        // {
        //     title: 'Цена',
        //     dataIndex: "price",
        //     render: (price: { max: number, min: number }) =>
        //         <span>{price.max - price.min}</span>

        // },
        {
            title: 'Цена мин',
            dataIndex: "price",
            ellipsis: {
                showTitle: true,
            },
            render: (price: { min: number }) =>
                <span>{price.min}</span>

        },
        {
            title: 'Цена мах',
            dataIndex: "price",
            ellipsis: {
                showTitle: true,
            },
            render: (price: { max: number }) =>
                <span>{price.max}</span>

        },
        {
            title: 'Эксклюзивность',
            dataIndex: 'is_exclusive',
            ellipsis: {
                showTitle: true,
            },
            render: (is_exclusive: { is_exclusive: boolean }) =>
                <span>{is_exclusive ? "есть" : "нет"}</span>

        },
        {
            title: 'Кол-во покупок максимальное',
            dataIndex: 'buy_count',
            ellipsis: {
                showTitle: true,
            },
            render: (buy_count: { max: number | null }) =>
                <span>{buy_count.max === null ? "---" : buy_count.max}</span>

        },
        {
            title: 'Кол-во покупок текущее',
            dataIndex: 'buy_count',
            ellipsis: {
                showTitle: true,
            },
            render: (buy_count: { current: number | null }) =>
                <span>{buy_count.current === null ? "---" : buy_count.current}</span>

        },
        {
            dataIndex: 'id',
            ellipsis: {
                showTitle: true,
            },
            render: (id: string) => (
                <Button id={`${id}`}
                    onClick={handleBuyClick}
                    style={{ outline: 'none', border: 'none', color: '#1890ff' }}>
                    Купить <RightOutlined />

                </Button>
            )
        }
    ]

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [selectedRowKey, setSelectedRowKey] = useState<any>({});
    const hasSelected: boolean = selectedRowKeys.length > 0;
    const [tableData, setTableData] = useState<any[]>([]);
    const [tablePagination, setTablePagination] = useState<number>(0);
    const [tablePageSize, setTablePageSize] = useState<any>(10);
    const [tableCurrent, setTableCurrent] = useState<any>(1);
    const [filters, setFilters] = useState<any>();
    const {isFilter, mode} = useQuery(queryParsers);


    const handleFilterOpen = () => {
        routeService.pushWithQuery(ROUTES.FIND_CLIENTS, {
            mode: EntityMode.FilterFindClients,
        })
    };

    const handleFilterSubmit = (formData: any) => {
        routeService.pushWithQuery(ROUTES.FIND_CLIENTS, {
            isFilter: EntityMode.Submit,
        })
        setFilters(formData);
        let Q =  {
            ...formData,
            offset: tablePageSize * (tableCurrent - 1),
            count: tablePageSize
        };
        let tmp: any;
        ordersApi.getRealtyData(Q)
            .then(res => {
                if (res) {
                    tmp = res;
                    setTableData(tmp.result.map((user: any) => { return { ...user, key: user.id } }));
                    setTablePagination(tmp.pagination.total);
                }
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
            });
    };
    

    const onSelectChange = (selectedRows: any[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRows);
    };
    const handleBuyClick = (event: any) => {
        operationsService.makePurchaseOperation({
            id: event.currentTarget.id,
            comment: 'new comment'
        })
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {
                console.log(error)
            })
        routeService.pushWithQuery(ROUTES.FIND_CLIENTS, {
            id: event.currentTarget.id
        },
            {
                shouldRefresh: true,
                reset: true
            })
        setTableCurrent(1)
    }


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    useEffect(() => {
        let Q = isFilter === EntityMode.Submit ? {
            ...filters,
            offset: tablePageSize * (tableCurrent - 1),
            count: tablePageSize
        } : {
            offset: tablePageSize * (tableCurrent - 1),
            count: tablePageSize
        };
        let tmp: any;
        ordersApi.getRealtyData(Q)
            .then(res => {
                if (res) {
                    tmp = res;
                    setTableData(tmp.result.map((user: any) => { return { ...user, key: user.id } }));
                    setTablePagination(tmp.pagination.total);
                }
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
            });

    }, [tablePageSize, tableCurrent, isFilter, filters])

    return (

        // {/* <div style={{ marginBottom: 16 }}>
        //   <span style={{ marginLeft: 8 }}>
        //     {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        //   </span>
        // </div> */}
        <div className='find-table'>
            <div className="find-table__header">
                <Typography.Title level={2}>Найти клиентов</Typography.Title>
                {/* <p className="find-table__title-sort">
                    Сортировка (по дате)
                </p> */}
            </div>
            {/* <div className="find-table__queries">
                <Input style={{ width: '412px', height: '50px' }} />
                <Select style={{ width: '231px', height: '50px', margin: '0 16px' }} />
                <Select style={{ width: '231px', height: '50px' }} />
            </div> */}
            <Table className="find-table__box"
                pagination={{
                    total: tablePagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', tablePagination.toString()],
                }}

                onChange={e => {
                    setTablePageSize(e.pageSize);
                    setTableCurrent(e.current)
                }}
                scroll={{ x: '100vw', y: '40vh' }}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData} />
               
                <TableSidebar sumbitClick={handleFilterSubmit} />
            <div className='find-table__buttons-box'>
                {/* <Button className='find-table__button'>{BUTTONS.findClientsAutoBuy}</Button> */}
                <div></div>
                <div className='find-table__right-box'>
                    <Space size={30}>
                        <Button onClick={handleFilterOpen} className='find-table__button'>{BUTTONS.findClientsNewSearch}</Button>
                        <Button className='find-table__button find-table__button-right'>{BUTTONS.findClientsBuy}</Button>
                    </Space>
                </div>
            </div>
        </div>
    )
}

export default memo(FindClientsTable);