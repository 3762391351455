import { Layout, Row, Col } from 'antd';
import React, { memo } from 'react';
import MainHeader from '../../app/components/header';
import NavigationMenu from '../../app/components/navigation-menu';
import './FindClients.scss';
import FindClientsTable from '../../app/components/find-clients-table';

const FindClients: React.FC = () => {

    return (
        <Layout className="page" >
            <Row className='page__wrap' gutter={[40, 8]} >
            <Col className='page__wrap page__wrap_menu' sm={4}>
                    <NavigationMenu />
                </Col>
                <Col className='page__wrap page__wrap_table' xs={24} sm={20}>
                    <MainHeader />
                    <FindClientsTable />
                </Col>
            </Row>
        </Layout>
    );
};

export default memo(FindClients);
