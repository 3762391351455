import React, { memo } from "react";
import { Card, Col, Row, Divider } from 'antd';
import './ClientCard.scss';

type PropsType = {
    id: string,
    email: string,
    balance: number,
    accountType: string,
    handleClick: any
}

const ClientCard: React.FC<PropsType> = (props) => {

    return (
        <div className="client-card site-card-wrapper" id={props.id} onClick={props.handleClick}>
            <Row gutter={16}>
                <Col span={8}>
                    <Card title="Имя аккаунта" bordered={false}>
                            {props.email}
                    </Card>
                </Col>
                
                <Col span={8}>
                    <Card title="Баланс" bordered={false}>
                        {props.balance}&nbsp;&#8381;
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Тип аккаунта" bordered={false}>
                        {props.accountType}
                    </Card>
                </Col>
            </Row>
            <Divider style={{backgroundColor: '#BDBDBD'}}/>
        </div>
    )
}

export default memo(ClientCard)