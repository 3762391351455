import React, { memo } from 'react';
import './InHeader.scss';
import Logotipe from '../../../core/images/SiteLOGO';

const InHeader: React.FC = () => {

    return (
        <div className="in-header">
            <Logotipe />
            <span className="in-header__motto">Совершайте финансовые
сделки в любом удобном месте</span>
        </div>
    )
}

export default memo(InHeader)