import { UserAction, UserActionTypes, UserState } from '../../types/user';

const initialState: UserState = {
    id: '',
    role: '',
    avatar_url: '',
    name: ' ',
    last_name: ' ',
    email: ' ',
    balance: 0,
}


export const userReducer = (state = initialState, action: UserAction): UserState => {

    switch (action.type) {
        case UserActionTypes.SAVE_USER:
            return {
                ...state,
                ...action.payload,
            }
        // case UserActionTypes.PUT_USER_SUCCESS:
        //     return {
        //         ...state,
        //         ...action.data,
        //     }
        // case UserActionTypes.PUT_USER_ERROR:
        //     return {
        //         ...state
        //     }
        default:
            return state
    }
}