import {Button, Drawer} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {memo} from 'react';
import {ROUTES} from '../../../core/consts/common';
import {useQuery} from '../../../core/hooks/useQuery';
import {routeService} from '../../../core/services/RouteService';
import {EntityMode} from '../../../core/types/EntityMode';
import {stringParser} from '../../../core/utils/queryParsers';
import FilterFindClientsReduxForm from '../filter-form-find-clients';
import './TableSidebar.scss';

type Props = {
    sumbitClick: (formData: any) => void
}

const AVAILABLE_CLOSE_MODES = [EntityMode.Show];

const queryParsers = {
    id: stringParser(),
    mode: stringParser<EntityMode>(),
    isFilter: stringParser<EntityMode>()
};

const handleClose = () => {
    routeService.push(ROUTES.FIND_CLIENTS);
}; 

const TableSidebar = (props: Props) => {
    const {mode} = useQuery(queryParsers);


    const onSubmit = (formData: any) => {
        props.sumbitClick(formData);
        handleClose();
        routeService.pushWithQuery(ROUTES.FIND_CLIENTS, {
            isFilter: EntityMode.Submit
        });
    };

    return (
        <>
            <Drawer
                visible={!!mode}
                closable={false}
                onClose={handleClose}
                width="450"
                title='Выберите фильтры'
                placement="right"
            >
                <FilterFindClientsReduxForm onSubmit={onSubmit}/>
            </Drawer>
        </>
    )
}

export default memo(TableSidebar);

