import { Layout, Row, Col } from 'antd';
import React, { memo } from 'react';
import MainHeader from '../../app/components/header';
import NavigationMenu from '../../app/components/navigation-menu';
import './PersonalAccountSuppler.scss';
import PersonalAccountSupplerData from '../../app/components/personal-account-supplier-data';
import { rootState } from '../../core/types/rootState'
import { useSelector } from 'react-redux';

const PersonalAccountSuppler: React.FC = () => {
    const id = useSelector((state:rootState) => state.user.id);
    return (
        <Layout className="page" >
            <Row className='page__wrap' gutter={[40, 8]} >
                <Col className='page__wrap page__wrap_menu' sm={4}>
                    <NavigationMenu />
                </Col>
                <Col className='page__wrap page__wrap_table' xs={24} sm={20}>
                    <MainHeader />
                    <PersonalAccountSupplerData 
                        user_id={id}
                    />
                </Col>
            </Row>
        </Layout>
    );
};

export default memo(PersonalAccountSuppler);
