import { TOKEN_NAME } from '../consts/common';

class TokenService {
    //Устанавливает куки с именем name и значением value, с настройкой path=/ по умолчанию
    _setCookie(name: string, value: string, options: any) {
        // options = {
        //     path: '/',
        //     ...options
        // };

        // if (options.expires instanceof Date) {
        //     options.expires = options.expires.toUTCString();
        // }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        // for (let optionKey in options) {
        //     updatedCookie += "; " + optionKey;
        //     let optionValue = options[optionKey];
        //     if (optionValue !== true) {
        //         updatedCookie += "=" + optionValue;
        //     }
        // }
        document.cookie = updatedCookie;
    }

    // возвращает куки с указанным name,
    // или undefined, если ничего не найдено
    _getCookie(name: string) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    _deleteCookie(name: string) {
        this._setCookie(name, "", {
            'max-age': -1
        })
    }

    setAccessToken(token: string) {
        this._setCookie(TOKEN_NAME, token, { secure: true, 'max-age': 3600 });
    }

    getAccessToken(): string | undefined {
        return this._getCookie(TOKEN_NAME) ?? undefined;
    }

    removeAccessToken() {
        this._deleteCookie(TOKEN_NAME);
    }

    setRefreshToken(token: string) {
        sessionStorage.setItem(TOKEN_NAME, token);
    }
    getRefreshToken() {
        return sessionStorage.getItem(TOKEN_NAME);
    }
    removeRefreshToken() {
        sessionStorage.removeItem(TOKEN_NAME);
    }
    parseJwt = (token: string) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };


}

const tokenService = new TokenService();

export default tokenService;


