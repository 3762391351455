export interface UserState {
    id: string;
    role: string;
    avatar_url: string;
    name: string;
    last_name: string;
    email: string;
    balance: number;
}

export enum UserActionTypes {
    SAVE_USER = 'SAVE_USER',
    PUT_USER_SUCCESS = 'PUT_USER_SUCCESS',
    PUT_USER_ERROR = 'PUT_USER_ERROR'
}
interface SaveUserAction {
    type: UserActionTypes.SAVE_USER;
    payload?: any;
    data?: UserState
}

interface PutUserSuccessAction {
    type: UserActionTypes.PUT_USER_SUCCESS;
    payload?: any;
    data?: UserState
}

interface PutUserErrorAction {
    type: UserActionTypes.PUT_USER_ERROR;
    payload: string;
}

export type UserAction = SaveUserAction | PutUserSuccessAction | PutUserErrorAction

