import React, { memo, useEffect, useState } from 'react';
import './Registration.scss';
import RegistrationOptions from '../../app/components/registration-options/index';
import RegistrationForm from '../../app/components/registration-form/index';
import InHeader from '../../app/components/in-header/index';
import AsidePicture from '../../app/components/aside-picture/index';
import { useSelector } from 'react-redux';
import { rootState } from '../../core/types/rootState';
import { ErrorType, RegistrationFormType } from './types';
import { InputNumber, Modal, Form, Button } from 'antd';
import registrationApi, { RegistrationResponseType } from '../../core/api/registrationApi';
import authApi from '../../core/api/authApi';
import { routeService } from '../../core/services/RouteService';
import { ROUTES } from '../../core/consts/common';
import userActivationService from '../../core/services/UserActivationService';
import tokenService from '../../core/services/TokenService';

const RegistrationRouter: React.FC = () => {
    const [isFirstStageShown, setIsFirstStageShown] = useState<boolean>(false);
    const [isSecondStageShown, setIsSecondStageShown] = useState<boolean>(false);
    const [isOptionsShown, setIsOptionsShown] = useState<boolean>(true);
    const isRegistrationType = useSelector((s: rootState) => s.registration.type)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userID, setUserID] = useState('');
    const [error, setError] = useState<ErrorType[]>([{ type: '', message: '', field: '' }]);

    useEffect(() => {
        console.log(isRegistrationType);
        if (isRegistrationType) {
            setIsOptionsShown(!isOptionsShown);
            setIsFirstStageShown(!isFirstStageShown);
        }
    }, [isRegistrationType])

    function handleContinueClick(): void {
        setIsFirstStageShown(!isFirstStageShown);
        setIsSecondStageShown(!isSecondStageShown);
    }

    function handleOptionsClick(): void {
        setIsFirstStageShown(!isFirstStageShown);
        setIsSecondStageShown(false);
        setIsOptionsShown(!isOptionsShown);
    }

    function handleBackStepClick(): void {
        if (isFirstStageShown && !isSecondStageShown) {
            setIsOptionsShown(true);
            setIsFirstStageShown(false);
            setIsSecondStageShown(false);
        }
        if (isSecondStageShown && !isFirstStageShown) {
            setIsFirstStageShown(true);
            setIsOptionsShown(false);
            setIsSecondStageShown(false);
        }
    }

    //modal window sms confirm handlers


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = (e: any) => {
        authApi.confirmSms({userId: userID, code: `${e.code}`})
        .then((res: any) => {
           tokenService.setAccessToken(res.access_token);
           tokenService.setRefreshToken(res.refresh_token);
           if (res) routeService.push(ROUTES.PROFILE)
        })
        .catch((e: any) => {
            let tmpArr = e.response.request.response.split('"')
            if (e.response.status === 400) {
                setError([{ message: tmpArr[tmpArr.length-2]  }])
            }
            else setError([{ message: 'Ошибка на сервере' }])
        })

        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

     
    const onSubmit = (formData: RegistrationFormType) => {
        if (formData.birth_date && formData.email) {
            const birthday = formData.birth_date.split('-').reverse().join('.');
            const dataSend = {
                ...formData,
                birth_date: birthday,
                login: formData.phone,
                role: isRegistrationType
            }

            return registrationApi.registration(dataSend)            
                .then((data: RegistrationResponseType | any): void => {                    
                    setUserID(data.id)
                    setPhoneNumber(formData.phone)
                    showModal();
                })
                .catch((e: any) => {
                    let tmpArr = e.response.request.response.split('"')
                    if (e.response.status === 400) {
                        setError([{ message: tmpArr[tmpArr.length-2]  }])
                    }
                    else setError([{ message: 'Ошибка на сервере' }])
                })
        }
    }

    const RegistrationFormProps = [isFirstStageShown, isSecondStageShown, handleContinueClick]

    return (
        <section className="registration">
            <AsidePicture />
            <div className="registration__wrap">
                <InHeader />
                <h3 className="registration__title">
                    Регистрация
                </h3>
                {isOptionsShown &&
                    <RegistrationOptions
                        {...handleOptionsClick}
                    />
                }
                <RegistrationForm
                    {...RegistrationFormProps}
                    onSubmit={onSubmit}
                />
                {error.map((e: ErrorType, index: number) => <p key={index} className="registration__error">{e.message}</p>)}
                {(isSecondStageShown || isFirstStageShown)
                    && <button onClick={handleBackStepClick} className="registration__back-step">
                        Предыдущий шаг
                    </button>}
            </div>

            <Modal
                footer={[
                    <Button form="smsForm" key="submit" htmlType="submit">
                        Отправить
                    </Button>
                ]}
                onCancel={handleCancel}
                visible={isModalVisible}
            >
                <Form name="smsForm" onFinish={handleOk}>
                    <p style={{ marginBottom: '10%' }}>В целях безопасности необходимо подтвердить ваш номер телефона. Пожалуйста, введите код из SMS, который мы отправили на номер {phoneNumber}</p>
                    <Form.Item
                        label="Код из SMS"
                        name="code"                        
                        rules={[{ pattern: /^([0-9]{5})$/, required: true, message: 'Пожалуйста, введите код из СМС' }]}
                    ><InputNumber />
                    </Form.Item>
                </Form>
            </Modal>
        </section>
    );
};

export default memo(RegistrationRouter);
