export const SMS = {
    description: 'СМС-сообщение отличный способ обратиться к Вашему клиенту на прямую и без посредников. Без труда, обратившись к нашему специалисту Вы можете настроить любой текст, любого оператора и цена при этом Вас удивит.',
    using: [
        'Актуализация старых баз клиентов',
        'Воронки продаж от клика до прихода в офис',
        'Уведомления о новых условиях и акциях',
         'Индвидуальные предложения для каждого клиента',
        'Выявление потребностей по средствам каскадных рассылок',
    ],
    price:[
        {'от': '0,5 ₽ / SMS'}, 
        {'до':'2,4 ₽ / SMS'}
    ]
   
};

export const IVR = {
    description: 'IVR, робот с голосовыми возможностями, который может Вам помочь в обратобки Ваших новых и старых баз большого объема с минимальными затратами, а в связке с СМС, добиться не только их актуализации, но и предлоящего похода к Вам в офис.',
    using: [
        'Актуализация старых баз клиентов',
        'Актуализация большого объема баз клиентов',
        'Напоминания о походах в офис',
         'Интеграция с вашей CRM - системой',
    ],
    price:[
        {'фиксированная': '0,2 ₽ / номер'}, 
        
    ]
};

export const Viber = {
    description: 'Месенджер, в котором находиться достаточно большая аудитория России, с котором можно коммуницировать по средствам объемных текстов и креативов.',
    using: [
        'Рассказывать о компании и поделиться своим УТП',
        'Формировать сообщества на основе базы',
        'Собирать сегменты по разным продуктам и работать с ними',
    ],
    price:[
        {'фиксированная': '0,5 ₽ / номер'}, 
        
    ]
};

